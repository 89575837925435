// Style preset
:root {
    // rows
    --msv-product-specification-row-odd-color: $bg--secondary;
    --msv-product-specification-row-even-color: #{$msv-white};
    --msv-product-specification-border: #{$msv-gray-300};

    // heading
    --msv-product-specification-heading-font-color: var(--msv-font-primary-color);
    --msv-product-specification-heading-font-size: var(--msv-body-font-size-xl);

    // body
    --msv-product-specification-title-font-color: var(--msv-font-primary-color);
    --msv-product-specification-text-font-color: var(--msv-font-primary-color);
    --msv-product-specification-body-font-size: var(--msv-body-font-size-m);
}

.ms-product-specification {
    padding-top:$gutter-xl;
    padding-bottom: $gutter-xl;
    &__icon-specifications {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        img {
            max-width: 100%;
        }

    }
    &__facts-ingredients {
        margin-top:$gutter-xl;
        overflow: hidden;
        &__ctn {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }
            opacity: 0;
            transform: translateY(-20px);
            height: 0;
            transition: opacity .15s, transform 0.25s;
            pointer-events: none;
            overflow: hidden;
            .opened & {
                opacity: 1;
                transform: translateY(0);
                height: auto;
                pointer-events: auto;
            }
        }
        &__heading {
            display: flex;
            align-items: center;
            margin-bottom: $gutter-xl;
            &:before,
            &:after {
                flex-grow: 1;
                background-color: #fff;
                content: "";
                display: inline-block;
                height: 1px;
            }
        }
        &__title {
            border: 1px solid #fff;
            border-radius: 4px;
            display: inline-block;
            font-size: 1.125rem;
            letter-spacing: 1px;
            margin-bottom: 0;
            padding: 1rem 2rem;
            @include media-breakpoint-down(sm){
                padding: 1rem;
            }
            text-transform: uppercase;
            cursor: pointer;
            @include add-icon-icomoon($arrow-down, after, true, 10px);
            &:after {
                transform: rotate(180deg);
            }
            .closed & {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__heading {
        @extend %h2;
        text-align: center;
        margin-top: 40px;
        padding-bottom: $gutter-m;
        margin-bottom: $gutter-xl;
        border-bottom: 2px solid #fff;
    }
    &__nutritional-values, .table_heading {
        &__ctn {
            flex-basis: 100%;
            @include media-breakpoint-up(md) {
                flex-basis: 50%;
                min-width: 50%;
            }
        }
        width: auto;
        max-width: 400px;
        margin: 1rem auto;

        @include media-breakpoint-down(md) {
            margin: 1rem 1rem;
            max-width: none;
        }
    }
    &__description {
        margin: 1rem 0 1rem 3rem;
        flex-grow: 1;
        @include media-breakpoint-up(lg) {
            flex-basis: calc(50% - 3rem);

        }
        @include media-breakpoint-down(md) {
            margin: 1rem 1rem;
        }
        &__top {
            margin-bottom: $gutter-y;
            > div, p {
                font-size: 1rem;
            }
            .label {
                font-weight: bold;
            }
            .value {
                display: inline-block;
            }
        }
        > div, p {
            font-size: 1rem;
            line-height: 1.5em;
        }
        .ms-product-specification__facts-ingredients.layout_row & {
            margin-bottom: 3rem;
        }
    }

    &__facts-ingredients {
        &.layout_row {
            .ms-product-specification__nutritional-values {
                flex-wrap: nowrap;
                border-top:1px solid #ffffff60;
                border-bottom:1px solid #ffffff60;
                display: flex;
                > div {
                    width: 50%;
                    display: grid;
                    grid-auto-rows: 1fr;
                    > div {
                        border-bottom: 1px solid #ffffff60;
                        background: #ffffff10;
                        &:nth-child(even) {
                            background: transparent;
                        }
                    }

                    &:first-child {
                        > div {
                            font-weight: bold;
                            border-bottom: 1px solid #ffffff60;
                        }
                    }
                    > div {
                        padding: 0.75rem 1rem;
                        text-align: left;
                    }
                }
            }
        }
    }
    &__bottom {
        flex-basis: 100%;
        text-align: center;
        font-size: .875rem;
        font-style: italic;
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
}
