@font-face {
  font-family: 'icomoon';
  src:  url('../../../webfonts/icomoon.eot?bi5m6u');
  src:  url('../../../webfonts/icomoon.eot?bi5m6u#iefix') format('embedded-opentype'),
  url('../../../webfonts/icomoon.ttf?bi5m6u') format('truetype'),
  url('../../../webfonts/icomoon.woff?bi5m6u') format('woff'),
  url('../../../webfonts/icomoon.svg?bi5m6u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-brand-claim-onlyfont:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e901";
}
.icon-sign-in:before {
  content: "\e902";
}
.icon-wishlist_empty:before {
  content: "\e903";
}
.icon-wishlist_full:before {
  content: "\e904";
}
.icon-basket:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-youtube:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-quickaddtocart:before {
  content: "\e90c";
}