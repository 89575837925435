$msc-promo-code-discount-heading-margin-top: 20px;
$msc-promo-code-discount-line-container-margin-top: 4px;
$msc-promo-code-discount-line-container-padding-bottom: 10px;
$msc-promo-code-discount-line-remove-btn-padding: 0 12px;
$msc-promo-code-alert-padding: 8px;

:root {
    --msv-promo-code-heading-color: var(--msv-font-primary-color);
    --msv-promo-code-heading-size: var(--msv-body-font-size-l);
    --msv-promo-code-link-color: var(--msv-font-primary-color);
    --msv-promo-code-link-size: var(--msv-body-font-size-m);
    --msv-promo-code-applied-discount-color: var(--msv-font-primary-color);
    --msv-promo-code-applied-discount-size: var(--msv-body-font-size-m);
    --msv-promo-code-border: var(--msv-font-primary-color);
    --msv-promo-code-form-input-bg: #{$msv-white};
    --msv-promo-code-form-input-font-color: var(--msv-font-primary-color);
    --msv-promo-code-form-input-border: var(--msv-btn-input-border-color);
    --msv-promo-code-form-input-size: var(--msv-body-font-size-l);
    --msv-promo-code-alert-bg: #{$msv-pink};
    --msv-promo-code-alert-color: var(--msv-error-color);
    --msv-promo-code-alert-border: var(--msv-error-color);
    --msv-promo-code-alert-size: var(--msv-body-font-size-m);
}

.msc-promo-code {
    &-heading {

    }

    &__discount {
        display: flex;
        justify-content: space-between;
        margin-top: $msc-promo-code-discount-heading-margin-top;

        &-heading {

        }

        &-value {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__line {
        &-container {
            border-bottom:1px solid $msv-gray-300;
            margin-bottom: $spacer;
            margin-top: 4px;
            padding-bottom: $spacer;
        }

        &-value {
            display: inline-block;
        }

        &__btn-remove {
            background: none;
            font-size: 12px;
            color:$msv-secondary;
            margin-left: $spacer * 0.5;
            padding: 0;
            @include add-icon-icomoon($trash, before, true, 0.5em);
            &:hover {
                color:#000;
            }
        }
    }

    &__input-box {

    }
}

.msc-alert-danger {
    @extend %validation-error;
    .msi-exclamation-triangle {
        display: none;
    }
    .msc-alert__line {
        font-size: .875rem;
        font-weight: 400;
    }
}
