.msc-price {
    &__actual {
        font-weight: bold;
    }
    &__strikethrough {
        margin-right: 0.5em;
        text-decoration: line-through;
    }

    &.discount-value {
        .msc-price__strikethrough {
            display: block;
            margin-right: 0;
        }
    }
}

.minPrice30d {
    font-size: 12px;
    margin-top: 4px;
    margin-right: 3rem;
    line-height: 1.3em;

    span {
        white-space: nowrap;
        text-decoration: line-through;
    }
}