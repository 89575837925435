.quantity {
    font-size: 1rem;
    display: flex;
    .quantity-input {
        appearance: none;
        border: 1px solid $msv-secondary;
        border-radius: 4px;
        color:$black;
        font-size: 1em;
        width: 4em;
        height: 2.4em;
        line-height: 2em;
        margin: 0 .5rem;
        text-align: center;
        -moz-appearance:textfield;
    }

    .quantity__controls {
        height: 2.4em;
        width: 2.4em;
        line-height: 2.4em;
        background: none;
        border: 1px solid $msv-secondary;
        border-radius: 4px;
        color:$msv-primary;
        padding:0;
        @include add-icon-icomoon($more);
        &:disabled {
            color: $msv-gray-300;
        }
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus{
            color: var(--msv-buybox-btn-font-color);
            background: $wafer-brown;
            border: 1px solid $wafer-brown;
            cursor:pointer;
        }
    }

    .increment {

        &.disabled {
            color: $msv-gray-300;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            color: var(--msv-buybox-btn-font-color);
        }
    }

    .decrement {
        @include add-icon-icomoon($less);
        &.disabled {
            color: $msv-gray-300;
            border-color: $msv-gray-300;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            color: var(--msv-buybox-btn-font-color);
            border: 1px solid $wafer-brown;
        }
    }
}
