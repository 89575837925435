$name-top-margin: 4px;
$rating-top-margin: 4px;
$price-top-margin: 4px;
$original-price-color: $msv-gray-500;
$original-price-right-margin: 4px;

$product-font: $msv-primary-font-family;

$product-title-color: var(--msv-font-primary-color);
$product-title-line-height: 21px;
$product-title-margin-bottom: 8px;
$product-title-margin-top: 20px;

$msv-product-card-swatch-item-size: 30px;

:root {
    --msv-product-card-swatch-item-size: #{$msv-product-card-swatch-item-size};
}

.msc-product-card {
    font-size: var(--msv-body-font-size-s);

    .msc-product-card__image {
        width: 100%;
    }

    .msc-product-card__name {
        margin-top: $name-top-margin;
    }

    .msc-product-card__price-container {
        margin-top: $price-top-margin;
    }

    .msc-product-card__original-price {
        color: $original-price-color;
        margin-right: $original-price-right-margin;
    }

    .msc-product-card__rating {
        margin-top: $rating-top-margin;
    }
}

.msc-product__title {
    @extend %h5;
    @include media-breakpoint-down(sm){
        font-size: 0.875rem;
    }
    text-decoration: none;
    white-space: normal;
}
a.msc-product__title {
    &:focus {
        color:$msv-primary;
        text-decoration: none;
    }
    @media (hover: hover) {
        &:hover {
            color:$msv-primary;
            text-decoration: none;
        }
    }
    &:focus {
        color:$msv-primary;
        text-decoration: none;
    }
}
a {
    &:focus {
        .msc-product__title {
            color:$msv-primary;
            text-decoration: none;
        }
    }
    @media (hover: hover) {
        &:hover {
            .msc-product__title {
                color:$msv-primary;
                text-decoration: none;
            }
        }
    }
}
.msc-product__dimensions {
    .msc-swatch-container {
        &__item {
            min-width: var(--msv-product-card-swatch-item-size);
            height: var(--msv-product-card-swatch-item-size);

            font-size: 12px;
        }
    }
}

.msc-product__details {
    border-top:2px solid $msv-red;
    padding-top: 0.875rem;
    position: relative;
    .msc-product__availability {
        font-size: 0.875rem;
    }
    .msc-price {
        font-size: 1.25rem;
        margin-right: 3rem;
        @include media-breakpoint-down(lg){
            font-size:1rem;
        }
        @include media-breakpoint-down(xs){
            font-size:0.875rem;
        }
    }
    .minPrice30d {
        font-size: 12px;
        margin-top:4px;
        margin-right: 3rem;
        line-height: 1.3em;
        span {
            white-space: nowrap;
            text-decoration: line-through;
        }
    }
}

.msc-product__image {
    position: relative;
    .msc-main_image {
        width: 100%!important;
    }
    picture {
        + picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: 0.25s linear;
        }
    }
}

.msc-product {
    @include image(315px,315px);
}

.msc-product-badge {
    &__ctn {
        position: absolute;
        top: .5rem;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        .ms-quickView__body &, .ms-buybox__content & {
            position: static;
            margin-bottom: 1rem;
        }
    }

    width: 4rem;
    height: 4rem;
    background-color: $msv-primary;
    border-radius: 50%;
    box-shadow: 0px 0px 6px #00000029;
    color:#fff;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 1.25rem;
    margin-right: .4rem;
    margin-bottom: .4rem;

    @include media-breakpoint-down(lg){
        width: 3rem;
        height: 3rem;
        font-size: 1rem;
    }
    .label {
        font-family: $msv-promo-badge-font;
        font-size: 11px;
        line-height: 1em;
        text-align: center;
        color: $wafer-brown;
        margin-top: 3px;
        @include media-breakpoint-down(lg){
            font-size: 8px;
        }
    }
    &.badge {
        &--image {
            background:transparent;
            box-shadow: none;
        }
        &--promo {
            .value {
                font-size: 1.55rem;
                line-height: 1;
                font-family: "Arial Narrow", Arial, sans-serif;
                letter-spacing: .4px;
                @include media-breakpoint-down(lg){
                    font-size: 1rem;
                }
            }
        }
        &--percentual {
            .value {
                font-family: "Arial Narrow", Arial, sans-serif;
                letter-spacing: .4px;
                span {
                    font-size:1rem;
                    @include media-breakpoint-down(lg) {
                        font-size:.75rem;
                    }
                }
            }
        }
        &--new {
            line-height: 1;
        }
    }
}