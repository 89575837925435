$layout-gutter:1rem;
$spacer-xs:4px;
$spacer-half:.5rem;
$spacer:1rem;
$spacer-m:1.5rem;
$spacer-l:2.5rem;
$spacer-xl:3rem;
$spacer-xxl:6rem;

$gutter-y:1rem;
$gutter-x:1rem;
$gutter-s:0.5rem;
$gutter-m:1.5rem;
$gutter-l:2rem;
$gutter-xl:3rem;