%icon-font-style {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  //line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon-button {
  border:0;
  outline: none;
  background:transparent;
}


@mixin add-icon-icomoon($icon, $selector: before, $next-to-text:false, $margin:$layout-gutter, $scale:false ) {
  &:#{$selector} {
    @extend %icon-font-style;
    content: $icon;
    display:inline-block;
    line-height: 1.3em;
    @if $next-to-text and $scale==true{
      transform: scale(1.25);
    }
    @if $next-to-text and $selector == 'before'{
      margin-right: $margin;
    }
    @if $next-to-text and $selector == 'after'{
      margin-left: $margin;
    }
  }
}

//icomoon
$hamburgher: "\e90b";
$search:"\e901";
$user: "\e902";
//$arrow: "\e917";
//$arrow-long: "\e90a";
//$chevron: "\e905";
//$more:  "\e907";
//$less: "\e906";
$error: "\e913";
//$star-half: "\e900";
//$star-full: "\e902";
//$star: "\e904";
$youtube: "\e908";
$instagram: "\e906";
$facebook: "\e909";
$twitter: "\e907";
//$linkedin: "\e90f";
//$pinterest: "\e90e";
$cart: "\e905";
$wishlist:"\e903";
$wishlist-full:"\e904";
$close: "\e90a";
$quickaddtocart: "\e90c";
$arrow-down: "\e90d";
$arrow-right: "\e91b";
$language: "\e90e";
$alert: "\e90f";
$taste:"\e910";
$check:"\e912";
$information:"\e911";
$percentage:"\e915";
$delivery:"\e914";
$more:"\e916";
$less:"\e917";
$discount:"\e918";
$trash:"\e919";
$marker:"\e91a";