$video-player-width: 100%;

// Style presets
:root {
    --msv-video-player-width: #{$video-player-width};
}

.ms-video-player {
    .msc-videoplayer {
        width: var(--msv-video-player-width);
    }
}
.msc-videoplayer__controls__fullscreenbtn__container {
    display: block!important;
    z-index: 10000;
    position: relative;
}