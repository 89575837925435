$homepage-hero-title-font-size: 64px;
$homepage-hero-title-line-height: 1.2;

:root {
    //Text theme
    --msv-hero-textheme-dark: var(--msv-font-primary-color);
    --msv-hero-textheme-light: var(--msv-font-secondary-color);

    // Heading
    --msv-hero-heading-size: #{$homepage-hero-title-font-size};

    //Body
    --msv-hero-body-size: #{$msv-font-size-m};

    //Cta
    --msv-hero-cta-color: var(--msv-accent-brand-color);
}
.hero {
    @include media-breakpoint-down(md){
        .msc-carousel__control__prev, .msc-carousel__control__next {
            top:calc(100vw * 0.75);
        }
        .msc-carousel__control__prev {
            left:$spacer;
            transform: translate(0%, -50%);
        }
        .msc-carousel__control__next {
            right:$spacer;
            transform: translate(0%, -50%);
        }
        .ms-content-block__image img,
        .ms-content-block[data-m-layout="full-width"] .ms-content-block__image img {
            width:100vw;
            max-width: 100vw;
        }
    }
    .ms-content-block__image, .ms-content-block__image img {
        border-radius: 0;
    }

}
.hero.ms-content-block {
    .ms-content-block__details {
        align-items: flex-start;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        left: 0;
        padding: $spacer $spacer-xxl $spacer-xl $spacer-xxl;
        @include media-breakpoint-down(md){
            padding:$spacer-l;
        }
        @media screen and (min-width: $msv-breakpoint-l) {
            padding: $spacer-xxl;
        }

        right: 0;
        top: 0;

        .ms-content-block__title {
            color: var(--msv-hero-textheme-dark);
            @extend %h1;
        }

        .ms-content-block__text {
            color: var(--msv-hero-textheme-dark);
        }

        .msc-cta__primary {
            @extend %primary-button;
        }

        .msc-cta__secondary {
            @extend %secondary-button;
        }
    }

    &.texttheme__light .ms-content-block__title, &.texttheme__light .ms-content-block__text {
        color: var(--msv-hero-textheme-light);
        @include media-breakpoint-up(lg){
            text-shadow: $hero-content-shadow;
        }
    }

    &.texttheme__dark .ms-content-block__title, &.texttheme__dark .ms-content-block__text {
        color: var(--msv-hero-textheme-dark);
    }
}

@media screen and (max-width: $msv-breakpoint-l) {
    .hero {
        .msc-carousel.slide {
            background-color: $bg--secondary;
        }
    }
    .hero.ms-content-block {

        .ms-content-block__details {
            color: $msv-black;
            position: static;
            text-decoration: none;
            text-align: left;

            .ms-content-block__title {
                font-size:1.75rem;
                color: $msv-black;
                max-width: initial;
                text-shadow: none;
            }

            .ms-content-block__text {
                color: $msv-black;
            }

            .ms-content-block__cta {
                width: 100%;

                .msc-cta__primary {
                    width: 100%;
                }
            }
        }
    }
}
