$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 32px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
    --msv-order-summary-bg: $msv-secondary;
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: var(--msv-body-font-size-l);
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: var(--msv-font-primary-color);
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-order-summary {
    &-wrapper {
        align-items: center;
        background-color: var(--msv-order-summary-bg);
        display: flex;
        flex-direction: column;
        margin: 0 0 $spacer-l 0;

        height: fit-content;
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @extend %h4;
        font-weight: 400;
        color: $msv-primary;
        width: 100%;
    }

    &__items {
        color: $msv-gray-500;
        font-size: 0.875rem;
        width: 100%;
        p {
           font-size: 0.875rem;
            margin-bottom: 10px;
        }

        .msc-promo-code {
            &__group {
                display: flex;
                padding-top: 8px;
                margin-bottom: 1rem;
            }

            &__input-box {
                flex: auto;
                margin-right: 8px;
                font-size: 12px;
                border: 1px solid $mountain-gray;
                border-radius: 4px;
                overflow: hidden;
                padding: 8px;
            }
        }
    }

    &__line {
        &-sub-total,
        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges,
        &-total-discounts {

        }

        &-total {
            color:#000;
            font-size: 1rem!important;
            padding: $spacer 0;
            border-top: 1px solid $mountain-gray;
        }
    }

    &__value {
        float: right;
        text-align: right;
        font-weight: var(--msv-font-weight-bold);
    }
}
