.ms-site-picker {
    z-index: 3015;
    align-items: center;
    height: 3rem;
    position: relative;
    @include media-breakpoint-up(lg){
        width: 2.5rem;
    }
    &__button {
        display: flex;
        align-items: center;
        text-indent: -999em;
        overflow: hidden;
        background: transparent;
        border: 0;
        color:$msv-white;
        cursor: pointer;
        padding:0;
        &:focus {
            outline: none;
        }
        @media (hover: hover) {
            &:hover {
                color:$wafer-brown;
            }
        }
    }

    &__span {
       @include add-icon-icomoon($language);
        display: block;
        font-size: 1.5rem;
        padding:0.5rem;
        text-indent: 0;
    }

    &__list {
        background-color: $msv-white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: block;
        list-style-type: none;
        width: auto;
        padding:0.5rem !important;
        position: absolute;
        right: 0;
        z-index: 2000;
        &__item {
            cursor: pointer;
            white-space: nowrap;
            &__link {
                width: 100%;
                margin:0.5rem;
                span {
                    &:after {
                        content:"";
                        background: transparent;
                        width: 100%;
                        height: 2px;
                        display: block;
                    }
                }
                &:hover,
                &:focus {
                    outline: none;
                    text-decoration: none;
                    span {
                        &:after {
                            background: $msv-secondary;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-site-picker {
        margin-left: 0;
        height: auto;
    }

    .ms-site-picker__drawer {
        .drawer__button {
            background: none;
            color:#000;
            border: none;
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            @include add-icon-icomoon($language);
            font-size: 1rem;
            text-align: left;
            &:before {
                font-size: 1.5rem;
                margin: 0 0.5rem 0 0.1rem;
            }

            .drawer__buttontext {
            }

            .ms-site-picker__drawer-close,.ms-site-picker__drawer-open {
                position: absolute;
                right: 10px;
                @include add-icon-icomoon($arrow-down);
                &:before {
                    font-size: 0.85rem;
                    color:$msv-primary;
                }
            }

            .ms-site-picker__drawer-open {
                transform: rotate(180deg);
            }
        }
        > div {
            padding-left: 2.2rem;
        }
        .ms-site-picker__list__item__link {
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;

            &:focus {
                outline: none;
            }

            .ms-site-picker__list__item__text {
                margin: auto 0;
            }
        }
    }
}
