$active-image-full-width: 100%;
$active-image-details-text-font-size-desktop: 88px;
$active-image-details-text-font-size-mobile: 56px;
$active-image-details-text-font-size-tablet: 88px;
$active-image-details-text-line-height-desktop: 100px;
$active-image-details-text-line-height-mobile: 60px;
$active-image-details-text-line-height-tablet: 100px;
$active-image-details-additional-content-text-line-height-tablet-mobile: 40px;
$active-image-product-card-image-height: 275px;
$active-image-product-card-image-width: 275px;
$msv-breakpoint-ml: 1200px;

:root {
    //Background
    --msv-active-image-wrapper-background-color: var(--msv-bg-color);
    --msv-active-image-overlay-background-color: #{$msv-black};

    //Color
    --msv-active-image-border-color: #{$msv-black};
    --msv-active-image-button-border-color: #{$msv-gray-300};
    --msv-active-image-textheme-dark: #{$msv-black};
    --msv-active-image-textheme-light: #{$msv-white};

    //Body
    --msv-active-image-details-color: #{$msv-gray-200};
    --msv-active-image-text-color: #{$msv-blue};
}

.msc-active-image {
    overflow: hidden;

    &-container {
        position: relative;
        @include image($active-image-full-width);
        border-radius: 4px;
        overflow: hidden;
        img {
            width: auto;
            height: auto;
        }
    }

    &-wrapper {
        position: relative;
    }

    &-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--msv-active-image-overlay-background-color);
        z-index: 2;
        cursor: pointer;
        opacity: 0.4;
    }

    &-canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
    }

    &-map {
        cursor: pointer;

        area:focus {
            outline: 1px solid var(--msv-active-image-border-color);
        }
    }

    canvas {
        // to remove default
        display: none;
    }
    &__area-with-dots {
        position: relative;
    }
    &__area-dot {
        appearance: none;
        background: #fff;
        border: none;
        border-radius: 1rem;
        color:#fff;
        cursor: pointer;
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        outline: none;
        width: 24px;
        height: 24px;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.25em 1em;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: all 0s ease-out;
        @media (hover: hover) {
            &:hover {
                width: auto;
                color:$msv-primary;
                transition: all .25s ease-out;
            }
        }
        &:active {
          //  pointer-events: none;
        }
    }

    .msc-active-image-product-card-container {
        position: absolute;
        z-index: 10;

        @media (max-width: $msv-breakpoint-l) {
            /* stylelint-disable-next-line declaration-no-important -- To override the inline styles for tablets and mobile devices. */
            left: calc(50% - 160px) !important;
            /* stylelint-disable-next-line declaration-no-important -- To override the inline styles for tablets and mobile devices. */
            top: calc(50% - 255px) !important;
        }

        .msc-active-image-product-card {
            border: 1px solid $msv-gray-300;
            border-radius: 4px;
            overflow: hidden;
            @media (max-width: $msv-breakpoint-ml) {
                position: absolute;
                z-index: 1001;
            }

            &-wrapper {
                height: auto;
                background-color: var(--msv-active-image-wrapper-background-color);
                padding: $spacer-m;
                max-width: 350px;
                width: 320px;

                .close-button {
                    @include add-icon-icomoon($close);
                    color:#000;
                    border: none;
                    appearance: none;
                    background: none;
                    position: absolute;
                    right:  $spacer;
                    top:  $spacer;
                    z-index: 3;
                }

                .msc-active-image-product__image {
                    @include image($active-image-product-card-image-width, $active-image-product-card-image-height);
                    margin-bottom:  $spacer-m;
                }
            }

            .msc-active-image-product {
                display: inline-block;
                cursor: default;
                text-decoration: none;

                .msc-active-image-product__image {
                    img {
                        display: inline-block;
                    }
                }

                .msc-active-image-product__details {
                    .msc-active-image-product__title {
                        @extend %h5;
                        &:after {
                            content: "";
                            background: $msv-primary;
                            display: block;
                            margin-bottom: 1rem;
                            margin-top: 1rem;
                            width: 6rem;
                            max-width: 80%;
                            height: 2px;
                        }
                    }

                    .msc-active-image-product__text {
                        display: none;
                    }

                    .msc-price {
                        font-size: 1.25rem;
                    }
                    .msc-rating__star {
                    }

                    .msc-rating__count {
                        display: inline-flex;
                    }
                }
            }

            .see-more-button {
                @extend %primary-button;
                margin-top:  $spacer-m;
            }
        }
    }

    &__details {
        align-items: flex-start;
        color: #fff;
        display: flex;
        height: 0;
        position: absolute;
        flex-direction: column;
        top: 0;
        z-index: 1;

        @include media-breakpoint-down(md){
            background: $msv-gray-900;
            margin: -4px auto 0 auto;
            padding: $spacer-m;
            position: relative;
            display: table;
            width: 100%;
        }
        @include media-breakpoint-up(lg){
            margin: 14% 0 14% 5.5%;
            max-width: 44.5%;
        }

        &__title {

        }

        &__text {

        }

        &__cta {
            max-width: fit-content;
            z-index: 56;
            pointer-events: auto;
            @include media-breakpoint-down(sm){
                max-width: 100%;
                width:100%;
            }
            &__link {
                @extend %primary-button;
                margin-top: 20px;

                @media (max-width: $msv-breakpoint-m) {
                    margin: $spacer 0 0 0;
                    width: 100%;
                    text-align: center;

                    > * {
                        margin: auto;
                    }
                }
            }
        }

        &__additional-content {
            display: block;
            margin-top: 40px;

            &__container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 8px;
                pointer-events: auto;

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin: auto;
                }
            }

            &__paragraph {

                padding-right: 20px;
            }

            &__text {
                margin-left: 17px;
            }

            &__cta {
                &__links {
                    padding-left: 20px;
                    padding-right: 20px;

                    &:nth-child(1) {
                        text-decoration-line: underline;
                        border-left: 1px solid var(--msv-active-image-details-color);
                        color: var(--msv-active-image-details-color);
                    }

                    @media (max-width: $msv-breakpoint-m) {
                        padding-right: 16px;
                    }
                }
            }

            @media (max-width: $msv-breakpoint-m) {
                position: relative;
                margin-top: 20px;
                text-align: center;

                &__text {
                    margin-left: 0;
                }

                &__container {
                    margin: 0 auto;
                    padding-top: 8px;
                    pointer-events: auto;
                }

                &__paragraph {
                    padding-left: 20px;
                }

                &__cta {
                    text-align: left;

                    &__links {
                        &:nth-child(1) {
                            padding-left: 20px;
                            padding-right: 20px;
                        }

                        &:nth-child(2) {
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    }
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                position: relative;
                margin-top: 15px;
                text-align: center;

                &__text {
                    margin: auto;
                    padding-bottom: 10px;
                }

                &-cta {
                    text-align: left;

                    &-links {
                        &:nth-child(1) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }

                        &:nth-child(2) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
    }

    &.texttheme__light {
        background: var(--msv-active-image-textheme-dark);

        .msc-active-image__details {
            color: var(--msv-active-image-textheme-light);
            pointer-events: none;

            &__title {
                color: var(--msv-active-image-textheme-light);
            }

            &__cta {
                &__link {
                    @extend %primary-button;
                }
            }

            &__additional-content {
                &__paragraph {
                    color: var(--msv-active-image-textheme-light);
                }

                &__text {
                    color: var(--msv-active-image-textheme-light);
                }
            }
        }
    }

    &.texttheme__dark {
        background: var(--msv-active-image-textheme-light);

        .msc-active-image__details {
            color: var(--msv-active-image-textheme-dark);
            pointer-events: none;

            &__title {
                color: var(--msv-active-image-textheme-dark);
            }

            &__cta {
                &__link {
                    @extend %primary-button;
                }
            }

            &__additional-content {
                &__paragraph {
                    color: var(--msv-active-image-textheme-dark);
                }

                &__text {
                    color: var(--msv-active-image-textheme-dark);
                }
            }
        }
    }

    &.texttheme__light .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
        color: var(--msv-active-image-textheme-light);
        border-left: 1px solid var(--msv-active-image-textheme-light);
    }

    &.texttheme__dark .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
        color: var(--msv-active-image-textheme-dark);
        border-left: 1px solid var(--msv-active-image-textheme-dark);
    }
}
