.ms-checkout-shipping-address {
    &__group-images {
        margin-bottom: 20px;
        min-height: 165px;

        &-header {
            display: none;
        }

        &-lines {
            clear: both;

            &-product {
                float: left;
                position: relative;
                margin-top: 12px;
                margin-right: 12px;
                margin-bottom: 20px;
            }

            &-product.product-image-wide {
                margin-right: 24px;
            }

            &-line-image {
                position: relative;
                width: 108px;
                height: 108px;
            }

            &-line-quantity {
                border-radius: 50%;
                border: 1px solid $msv-green;
                background-color: $msv-white;
                position: absolute;
                width: 30px;
                height: 30px;
                bottom: 0;
                right: -15px;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
                z-index: 1;
            }
        }
    }
}
