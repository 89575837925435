.ms-tile-list {
    &-container {
        display: block;
        position: relative;
        transition: all .25s linear;
        border-radius: 4px;
        overflow: hidden;
        * {
            transition: all .25s linear;
        }
        &:hover {
            .ms-tile-list__title {
                font-size: 2rem;
            }
            .ms-tile-list__image {
                &:after {
                    opacity: 0.6;
                }
            }
            .ms-tile-list__heading {
                left:0;
                max-width: calc(100% - 1rem);
                margin-bottom: 1rem;
                .ms-tile-list__title {
                    &:after {
                        transform:scaleX(1);
                    }
                }
            }
            .ms-tile-list__text {
                max-height: 200px;
                height: auto;
                p {
                    font-size: 1rem;
                }

            }
            .ms-tile-list__thumbnail_image {
                bottom: 100%;
                margin-bottom: 1rem;
                max-width: 5rem;
            }
        }
    }
    &__image {
        &:after {
            content: "";
            background: #000;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            z-index: 0;
            transition: opacity 0.5s ease-out;
        }
    }
    &__content {
        position: absolute;
        bottom: 0;
        left: $spacer-m;
        width: calc(100% - #{$spacer-m * 2});
        white-space: normal;
        color:#fff;
    }
    &__heading {
        position: relative;
        .ms-tile-list__title {
            @extend %h4;
            margin-bottom: 0.5rem;
            display: inline-block;
            &:after {
                content: "";
                display: block;
                height: 4px;
                width: 100%;
                transform:scaleX(0);
                margin-top: 0.5rem;
                background: $msv-primary;
                transition: all .5s ease-out;
            }
        }
    }
    &__text {
        max-height: 0;
        height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        font-size: 10px;
        p {
            font-size: 10px;
            margin-bottom: $spacer-m;
        }
    }
    &__thumbnail_image {
        position: absolute;
        bottom: $spacer-m;
        max-width: 3rem;
        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }
        + .ms-tile-list__heading {
            max-width: calc(100% - 5rem);
            left: 4rem;
        }
    }
    &__links {
        display: none!important;
    }

    .msc-ss-carousel {
        @include media-breakpoint-down(xs) {
            margin-left: -20px;
            margin-right: -20px;
            width: auto;
            .msc-ss-carousel__flipper {
                left:20px;
                &--next {
                    left:auto;
                    right:20px;
                }
            }
        }
        .msc-tooltip {
            visibility: hidden;
        }

        .msc-flipper {
            &.msc-ss-carousel__flipper {
                top:50%;
            }
            &.msc-ss-carousel__flipper--prev {

            }
            &.msc-ss-carousel__flipper--next {

            }
        }

        .ms-tile__item {
            display: inline-block;
            max-width: none;
            overflow: hidden;
            text-align: left;
            vertical-align: top;
            transition: transform .4s;
            position: relative;
            margin-right: 20px;
            width: 315px;
            min-width: 315px;
            @include media-breakpoint-down(xs){
                width:100%;
                min-width:100%;
                margin-right: 0;
                padding:0 1.5rem;
                .ms-tile-list__image img {
                    width:100%;
                }
            }
        }
    }
}