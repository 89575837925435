
@mixin heading() {
  line-height: 1.2em;
  margin-bottom: $gutter-y;
  display: block;
}

h1, %h1 {
  @include heading;
  font-size: 3.25rem;
  font-weight: bold;
  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
}
h2,%h2 {
  @include heading;
  font-size: 2.5rem;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}
h3,%h3 {
  @include heading;
  font-size: 2rem;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    font-size: 1.375rem;
  }
}
h4, %h4 {
  @include heading;
  font-size: 1.375rem;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}
h5, %h5 {
  @include heading;
  font-size: 1rem;
  font-weight: $msv-font-weight-heavy;
}

p {
  font-size:1.25rem;
  margin-bottom: 1rem;
  line-height: 1.3em;
  @include media-breakpoint-down(lg) {
    font-size: 1.15rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
  .fontsize__small & {
    font-size:1rem;
  }
  .fontsize__large & {
    font-size:1.5rem;
  }
  .fontsize__extralarge & {
    font-size:2rem;
  }
  &.small {
    font-size:1rem!important;
  }
  &:last-child {
    margin-bottom:0;
  }
}
strong {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}

.bigger {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}
