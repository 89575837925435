$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$cart-empty-image-width: 150px;
$cart-empty-image-width-m: 80px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;
$cart-content-margin-left: 170px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;


.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
}
.msc-promo-code__apply-btn {
    border-radius: 4px;
    border:1px solid $cream-brown;
    color:$cream-brown;
    font-size: 0.875rem;
    font-weight: bold;
    padding:10px 1em;


}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    margin-top: 10px;
    width: $msv-checkout-button-width;
    font-size: 1rem;
}

.msc-cart__btn-checkout {
    @extend %primary-button;
}
.msc-cart__btn-guestcheckout {
    @extend %secondary-button;
}

.msc-cart__btn-backtoshopping {
    @extend %hyperlink;
    font-size: 0.875rem;
    margin:$spacer-m 0;
}

.ms-cart {
    margin: $spacer-l auto;
    @include media-breakpoint-up(xl){
        display: grid;
        grid-template-columns: auto 400px;
        grid-column-gap:4rem;
    }

    flex-wrap: wrap;

    .msc-cart__heading {
        @extend %h4;
        font-weight: 400;
        color: $msv-primary;
        flex: 0 0 100%;
    }

    .msc-cart__promotion {

        width: 100%;
        background-color: $msv-white;
        padding: $cart-promotion-padding;
        margin-bottom: $cart-promotion-margin-bottom;

        &_link {
            margin-left: 8px;
            text-decoration: underline;
            cursor: pointer;
        }

        .msi-promotion-icon {
            margin-right: $msv-promotion-icon-right-margin;

            @include add-icon($msv-DollarSign, before);
            display: none;
        }

        &-popup {
            position: absolute;
            width: auto;
            height: auto;
            z-index: 2;
            background-color: $msv-white;
            left: 0;

            &_content {
                color: var(--msv-font-primary-color);
                background-clip: padding-box;
                background-color: var(--msv-bg-color);
                border: 1px solid $msv-gray-400;
                outline: 0;
                pointer-events: auto;
                position: relative;
                width: 400px;
                min-width: 335px;
                padding: 20px 10px;

                .msc-cart__promotion-popup_title {
                    @include font-content-xl();
                    margin: 0;
                    text-transform: none;
                }

                .msc-cart__promotion-popup_close-button {
                    @include add-icon($msv-x-shape);
                    @include vfi();
                    background-color: transparent;
                    border: 0;
                    color: var(--msv-font-primary-color);
                    font-size: 20px;
                    padding: $cart-promotion-popup-close-button-padding;
                    position: absolute;
                    right: 15px;
                    text-shadow: 0 1px 0 var(--msv-bg-color);
                    top: 22px;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    .msc-cart__promotion-popup_name {
                        display: block;
                    }
                }
            }
        }
    }

    .msc-cart__btn-addcarttotemplate.disabled {
        background-color: $msv-white;
        color: $msv-gray-300;
        text-decoration: underline;
    }

    .msc-cart__btn-addcarttotemplate-tooltip {
        opacity: 1;

        .msc-tooltip-inner {
            background-color: $msv-white;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
            color: $msv-black;
            font-size: 14px;
            line-height: 16px;
            padding: 12px;
            text-align: left;
        }

        .msc-arrow::before {
            border-top-color: $msv-white;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        .msc-cart__promotion {
            &-popup {
                &_content {
                    width: 335px;
                }
            }
        }
    }

    .msc-cartline-wraper {
        border-top: 1px solid var(--msv-cart-border);
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;
        margin-right: $cart-cartline-wrapper-margin-right;
    }

    .msc-cart-line {
        position: relative;

        &__product-image {
            @include image($cart-empty-image-width);
            min-width: $cart-empty-image-width;
        }

        &__invoice-image {
            @include invoice-image($cart-empty-image-width);
        }

        &__content,
        &__bopis-container,
        &__extra-actions {

        }
    }

    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist ~ .msc-cart-line__remove-item {
        margin-left: auto;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-lines-item > .msc-cart-line__remove-item,
    // .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
    .msc-cart-line__extra-actions {
        margin-left: $cart-content-margin-left;
        align-self: flex-start;
        transform: translateY(-100%);
    }

    .msc-promo-code {
        &__input-box {

        }
    }

    .msc-cart-lines-group {
        flex: 1;
    }

    .msc-cart-lines-group-wraper {
        background-color: $msv-white;
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;
        padding: $spacer-m;

        .msc-cart-line-group__extra-actions {
            display: flex;
            margin-left: $cart-line-group-extra-action-margin-left;
            margin-top: $cart-line-group-extra-action-margin-top;

            .msc-tooltip {
                opacity: 1;

                .msc-tooltip-inner {
                    background-color: $msv-white;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
                    color: $msv-black;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 12px;
                    text-align: left;
                }

                .msc-arrow::before {
                    border-top-color: $msv-white;
                }
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-cart {
        display: block;

        .msc-cartline-wraper .msc-cart-lines-group-wraper {
            margin-right: 0;
            width: 100%;
        }

        .msc-cart-lines-group {
            margin-right: 0;
        }

        .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
        .msc-cart-lines-item > .msc-cart-line__remove-item {
            margin-left: $cart-cartline-item-content-margin-left-m;
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                &__product-image {
                    @include image($cart-empty-image-width-m);
                }

                &__invoice-image {
                    @include invoice-image($cart-empty-image-width-m);
                }

                &__content {
                    display: block;
                }

                &__bopis-container,
                &__extra-actions {
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__product {
                    padding-bottom: $cart-cartline-line-product-padding-bottom;

                    &-quantity {
                        padding-bottom: $cart-cartline-line-product-padding-bottom;
                    }

                    &-savings {
                        margin-left: 0;
                        text-align: left;
                    }

                    &-price {
                        margin-left: $cart-cartline-line-product-price-margin-left;
                        padding-top: $cart-cartline-line-product-price-padding-top;
                        text-align: left;
                    }
                    &-image {
                        min-width: 80px;
                    }
                }

                &-group {
                    &__extra-actions {
                        margin-left: $cart-cartline-item-content-margin-left-m;
                        margin-top: $cart-line-group-extra-action-margin-top;
                    }
                }
            }
        }

        .msc-promo-code {
            &__input-box {
                width: $cart-promo-code-input-width-m;
            }

            &__apply-btn {
                min-width: auto;
            }
        }

        .msc-order-summary-wrapper {
            padding: $cart-order-summary-padding-m;
            width: 100%;
        }

        .msc-order-summary__items {
            @include font-content-s();
        }
    }
}
