.delivery-progress-bar {
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    * {
        transition: all 0.25s ease-out;
    }
    &__line {
        border-radius: 1rem;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 1rem;
        top:1rem;
        &__ctn {
            position: relative;
            height: 3rem;
            margin-bottom: 0.5rem;
        }
        > span {
            background: #fff;
            border-radius: 1rem;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            &.delivery-progress-bar__total {
                width: 100%;
            }
            &.delivery-progress-bar__result {
                background: $wafer-brown;
            }
        }
    }

    .icon-discount {
        border-radius: 100%;
        position: absolute;
        color:$cream-brown;
        background: #fff;
        border:2px solid $wafer-brown;
        width: 1.2em;
        height: 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);

        &--partial {
            @include add-icon-icomoon($percentage);
            font-size: 1.5rem;
        }
        &--total {
            @include add-icon-icomoon($delivery);
            width: 2.6em;
            height: 2.6em;
        }
    }
    &__text-info {
        color:$cream-brown;
        text-align: center;
        > div {
            font-size:1rem;
            margin-bottom:.5rem;
        }
    }
}
