.ms-nav {
  > ul {
    padding:0.5rem 9rem!important;
    > li > a, > li > button {
      margin: 0.5rem 0.8rem;
      color:#000;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    outline: none;
    position: relative;
    width: 100%;
    justify-content: center;
    a, button {
      font-weight: 600;
      display: block;
      position: relative;
      padding:0.5rem 0 4px 0;
      text-decoration:none;
      
      &.mobile-only {
        font-weight: bold;
        font-size:30px!important;
        @include media-breakpoint-up(lg){
          display: none!important;
        }
      }
      &:before {
        content: "";
        background: $msv-primary;
        height: 2px;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.25s;
      }
      &:hover, &:focus {
        &:before {
          width: 100%;
        }
      }
    }
    button {
      background: transparent;
      border: none;
      cursor: pointer;

      @include add-icon-icomoon($arrow-down, after, true, 0.5rem);
      &:after {
        color:$msv-primary;
        font-size: 0.875rem;
      }
      &:hover, &:focus {
        &:before {
          width: calc(100% - 1.4rem);
        }
      }
      + div {
        position: absolute;
        left: 0;
        background: #fff;
        box-shadow: 0px 0px 6px #00000029;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 101;
        > ul {
          margin: auto;
          width: auto;
          display: flex;
          align-items: start;
          flex-wrap: wrap;
          padding:1rem 0;
          justify-content: start;
          > .ms-nav__list__item {
            margin:0 1.5rem;
            > a {
              color:$msv-primary;
              display: inline-block;
              font-size: 0.875rem;
            }
          }
          .ms-nav__list  {
            display: block;
            margin-bottom: 1rem;
            a {
              font-weight: normal;
              display: inline-block;
              padding-bottom: 4px;
              font-size: 0.875rem;
              &:hover, &:focus {
                &:before {
                  background: $msv-secondary;

                }
              }
            }
          }
        }

      }
    }
  }
}