.msc-add-to-wishlist {
    @include vfi();
    @include add-icon-icomoon($wishlist);
    &__removing {
        @include add-icon-icomoon($wishlist-full);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
