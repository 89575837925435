main {
    overflow-x: hidden;
}
html {
    overflow-x: hidden;
    min-height: -webkit-fill-available;
}
body {
    min-height: -webkit-fill-available;
}

.bg--secondary {
    background:$bg--secondary;
    position: relative;
    &:before, &:after {
        background: $bg--secondary;
        content:"";
        width: 1000%;
        height: 100%;
        position: absolute;
        top: 0;
        left:0;
        z-index: -1;
        transform: translateX(-50%);
    }
    &.bg--creamBrown {
        color:#fff;
        background:$cream-brown;
        &:before, &:after {
            background: $cream-brown;
        }
    }
    &:after {
        transform: translateX(50%);
    }
}