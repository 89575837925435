$msv-wishlist-items-width: 310px;
$msv-wishlist-items-width-m: 310px;
$msv-wishlist-items-empty-image-height: 310;
$msv-wishlist-items-heading-spacing: 20px;
$msv-wishlist-item-margin: 0 20px 60px 0;
$msv-wishlist-item-height: 468px;
$msv-wishlist-item-margin-mobile: 0 20px 60px 0;
$msv-wishlist-item-image-margin-bottom: 20px;
$msv-wishlist-item-image-size: $msv-wishlist-items-width;
$msv-wishlist-item-image-width-m: $msv-wishlist-items-width-m;
$msv-wishlist-item-price-original-font-color: $msv-gray-300;
$msv-wishlist-item-price-original-line-height: 16px;
$msv-wishlist-item-price-current-font-color: var(--msv-font-primary-color);
$msv-wishlist-item-dimension-font-size: 12px;
$msv-wishlist-item-dimension-line-height: 16px;
$msv-wishlist-item-dimension-margin-right: 10px;
$msv-wishlist-item-dimension-label-margin: 5px;
$msv-wishlist-item-add-button-icon-margin-right: 8px;
$msv-wishlist-item-add-button-margin: 20px auto 0 0;
$msv-wishlist-item-remove-button-right: 0;
$msv-wishlist-item-remove-button-padding: 6px 12px;
$msv-wishlist-item-message-padding: 10px;
$msv-wishlist-item-product-status-max-width: 310px;
$msv-wishlist-items-message-empty-margin-bottom: 32px;

//style presets
:root {
    --msv-wishlist-font-size: var(--msv-body-font-size-m);
    --msv-wishlist-title-font-color: var(--msv-font-primary-color);

    // heading
    --msv-wishlist-heading-font-size: var(--msv-body-font-size-xl);
    --msv-wishlist-heading-font-color: var(--msv-font-primary-color);

    // error
    --msv-wishlist-error-bg: var(--msv-error-color);
    --msv-wishlist-error-border: var(--msv-error-color);
    --msv-wishlist-error-font-color: var(--msv-font-secondary-color);

    // link
    --msv-wishlist-link-color: var(--msv-font-primary-color);

    // button
    --msv-wishlist-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-wishlist-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-wishlist-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-wishlist-items {
    @include font-content(var(--msv-font-weight-normal), var(--msv-wishlist-font-size), $msv-line-height-m);
    margin-bottom: $spacer-l;
    &__heading {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-wishlist-heading-font-size), $msv-line-height-xl);
        color: var(--msv-wishlist-heading-font-color);
        margin-bottom: $msv-wishlist-items-heading-spacing;
    }

    &__message {
        @include font-content-l();
        padding: $msv-wishlist-item-message-padding;

        &-failed {
            color: var(--msv-wishlist-error-font-color);
            background-color: var(--msv-wishlist-error-bg);
            border: 1px solid var(--msv-wishlist-error-border);
        }

        &-empty {
            color: var(--msv-wishlist-title-font-color);
        }
    }

    &__products {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: $spacer-m;

        @include media-breakpoint-down(md){
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: $spacer;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        min-height: $msv-wishlist-item-height;
        position: relative;
        background:#fff;
        padding:$spacer;
        width: 100%;
        @include image($msv-wishlist-item-image-size);

        &-image {
            margin-bottom: $msv-wishlist-item-image-margin-bottom;
        }

        &-link {
            @extend %h5;
            text-decoration: none;
            flex-grow: 1;
            &:hover, &:focus {
                color:$msv-primary;
                text-decoration: none;
            }
            width: auto;
        }

        &-price {

            // not needed when Price component is moved to utilities folder
            .price-text-screen-reader {
                display: none;
            }
        }

        &-dimensions {
            color: var(--msv-wishlist-title-font-color);
            display: flex;
            &:before {
                content:"";
                background: $msv-red;
                display: block;
                margin-bottom: 1rem;
                width:6rem;
                max-width: 80%;
                height: 2px;
            }
        }

        &-dimension {
            margin-right: $msv-wishlist-item-dimension-margin-right;

            .msc-wishlist-dimension {
                &__label {
                    margin-right: $msv-wishlist-item-dimension-label-margin;
                }
            }
        }

        &-add-button {
            @extend %primary-button;
            font-size:1rem;
            width: 100%;
            @include add-icon-icomoon($cart, after, true, 1rem, true);
            margin-top:$spacer-m;
        }

        &-remove-button {
            @include vfi();
            @include add-icon($msv-x-shape);
            background-color: transparent;
            border: 1px solid transparent;
            color: var(--msv-font-primary-color);
            padding: $msv-wishlist-item-remove-button-padding;
            position: absolute;
            right: $msv-wishlist-item-remove-button-right;
            cursor: pointer;
        }

        &-status {
            // max-width: $msv-wishlist-item-product-status-max-width;

            .msc-alert {
                button.close {
                    background-color: transparent;
                    border: none;
                    float: right;
                }
            }
        }

        .entire-product-link {
            display: block;
            overflow: hidden;

            &:hover {
                text-decoration: none;

                .ms-wishlist-items__product-title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__inventory-label {
        color: var(--msv-wishlist-link-color);
    }

    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-wishlist-items-message-empty-margin-bottom;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__product {

            &-image {
                width: $msv-wishlist-item-image-width-m;
            }
        }

        @include image($msv-wishlist-items-empty-image-height);
    }

    @include image($msv-wishlist-items-empty-image-height);
}
