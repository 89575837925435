$feature-link-right-margin: 16px;
$feature-links-top-margin: 32px;
$feature-link-to-link-spacing: 16px;
$feature-secondary-link-to-link-padding-top-bottom: 8px;
$feature-secondary-link-to-link-padding-left-right: 16px;
$feature-content-textplacement-left-margin: auto 5rem auto 5rem;
$feature-content-textplacement-right-margin: auto 5rem auto 5rem;
$feature-content-text-margin-top: 21px;
$feature-content-button-margin-top: 32px;
$feature-cta-font-size: 14px;
$feature-cta-line-height: 20px;
$feature-content-mobile-margin-top: 21px;

:root {
    // heading
    --msv-content-block-heading-font-size: var(--msv-body-font-size-xxl);
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);

    // text
    --msv-content-block-text-font-size: var(--msv-body-font-size-l);
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);

    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-content-block {
    &[data-m-layout="left-right"] {
        @include image(100%);

        .ms-content-block__image {
            width: 100%;
        }

        .ms-content-block__details {
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(sm){
                margin-bottom: $spacer-l;
            }
        }

        .msc-cta__primary {
           @extend %primary-button;
           margin-right: 1.5rem;
        }

        .msc-cta__secondary {
         
        }

        .ms-content-block__title {
            color: var(--msv-content-block-heading-font-color);
        }

        .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);

        }

        .ms-content-block__cta {
            display: flex;
            align-items: flex-end;
        }

        &.textplacement__left {
            .ms-content-block__details {
                .card-cta {
                    justify-content: flex-start;
                }
            }
        }

        &.textplacement__right {
            .ms-content-block__details {
                .card-cta {
                    justify-content: flex-end;
                }
            }
        }

        &.textplacement__center {
            .ms-content-block__details {
                text-align: center;

                .card-cta {
                    justify-content: center;
                }
            }
            .ms-content-block__cta {
                justify-content: center;
            }
        }

        .ms-content-block__image img {
            width: 100%;
        }

        .ms-content-block__image .ms-editable-field {
            /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
            width: 100% !important;
        }

        /* body should have top margin only when there's something above it */
        * + .ms-content-block__text {
            margin-top: $feature-content-text-margin-top;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $feature-content-button-margin-top;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            &.textplacement__right {
                display: grid;
                grid-template-columns: .9fr 1.1fr;
                grid-template-rows: auto;

                .ms-content-block__details {
                    margin: auto 2rem;
                    .msc-carousel__item & {
                        @include media-breakpoint-up(lg){
                            margin-right:5rem;
                            margin-left:5rem;
                        }
                    }
                }
            }
            .col-12 > &.textplacement__right {
                @include media-breakpoint-up(lg){
                    grid-template-columns: 3fr 2fr;
                    .ms-content-block__details {
                        margin: $feature-content-textplacement-right-margin;
                    }
                }
            }

            &.textplacement__center {
                .ms-content-block__details {
                    align-items: center;
                    text-align: center;
                }

                .ms-content-block__image img {
                    width: 100%;
                }

                .ms-content-block__image .ms-editable-field {
                    /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style in editorial mode. */
                    width: 100% !important;
                }
            }

            &.textplacement__left {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;

                .ms-content-block__image {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                    margin-bottom: auto;
                    margin-top: auto;
                }

                .ms-content-block__details {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                    margin: auto 2rem;
                    .msc-carousel__item & {
                        @include media-breakpoint-up(lg){
                            margin-right:5rem;
                            margin-left:5rem;
                        }
                    }
                }
            }
            .col-12 > &.textplacement__left {
                @include media-breakpoint-up(lg){
                    grid-template-columns: 2fr 3fr;
                    .ms-content-block__details {
                        margin: $feature-content-textplacement-left-margin;
                    }
                }
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__details {
                margin-top: $feature-content-mobile-margin-top;
            }

            .ms-content-block__image img {
                width: 100%;
            }

            .ms-content-block__image .ms-editable-field {
                /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
                width: 100% !important;
            }
        }
    }
}
