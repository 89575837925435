$buybox-margin-top: 36px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 20px;
$buybox-box-bottom-margin: 20px;
$buybox-store-description-margin-bottom: 8px;
$buybox-add-to-cart-extra-actions-margin: 8px;
$buybox-add-to-cart-extra-actions-width: 48px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width:66%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$buybox-custom-amount-input-width: 80px;
$buybox-custom-amount-input-padding:5px;

//style presets
:root {
    --msv-buybox-btn-bg: #{$msv-white};
    --msv-buybox-btn-font-color: #{$msv-primary};
    --msv-buybox-btn-border-color: var(--msv-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-buybox-rating-star: #{$msv-secondary};
    --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-buybox-btn-wishlist-color: #{$msv-secondary};

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: #{$msv-primary};
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: none;

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: #{$msv-secondary};

    // Flippers
    --msv-buybox-carousel-flipper-color: #{$msv-gray-900};
    --msv-buybox-carousel-flipper-hover-color: #{$msv-gray-800};
}



.ms-buybox {
    margin-top: $spacer-m;
    display: flex;
    flex-flow: row;
    margin-bottom: $gutter-xl;

    &__product-title {
        @extend %h2;
    }

    &__inventory-info {
        font-weight:bold;
        margin-top: $spacer;
    }
    &__inventory-label {

    }
    &__inventory-code {
        &-avail {
            color:$msv-green;
        }
    }

    &__product-description {
        font-size: 1rem;
    }

    &__product-taste-size {
        color:$msv-secondary;
        display: flex;
        align-items: center;
        margin-bottom: $spacer;
        @include add-icon-icomoon($taste);
        &:before {
            font-size: 3rem;
            margin-right: $spacer*0.5;
        }
        font-size: 0.875rem;
        .label {
            font-weight: bold;
        }
        .value {
            display: inline-block;
            font-weight: bold;
        }
    }

    .msc-price {
        font-size:1.6rem;
        display: block;
        margin: $spacer-m 0;
    }
    .minPrice30d {
        font-size:12px;
        margin-top: -1rem;
        span {
            text-decoration: line-through;
        }
    }

    .msc-rating {
        &__count{
            color: #000;
        }

        &__star {
            color: var(--msv-buybox-rating-star);
        }

        &__half-star {
            &::after {
                color: var(--msv-buybox-rating-star);
            }
        }
    }


    &__media-gallery {
        display: block;
        flex-basis: $media-gallery-width;
        min-width: $media-gallery-width;
        flex-grow: 1;
        flex-wrap: nowrap;
        padding-right: $gutter-width;
    }

    .msc-dropdown__select {
        @include form-control();
    }

    &__quantity {
        margin-top: $spacer;
        .quantity {
            font-size:1.15rem;
        }
    }

    &__product-quantity-label {
       display: none;
    }

    .ms-buybox__key_in_price {
        margin-top: 20px;

        .ms-buybox__key_in_price-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
            display: block;
            margin-bottom: $buybox-label-margin-bottom;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            @include form-control();
            -moz-appearance: textfield;
            padding: $buybox-custom-amount-input-padding;
            text-align: left;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .ms-text-block {
    }

    .product-add-to-cart {
        margin-top: $spacer-m;

        .buttons {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }

        .msc-add-to-cart {
            @extend %primary-button;
            flex-grow: 1;
            @include add-icon-icomoon($cart, after, true, 1rem, true);
            max-width: calc(100% - 3.5rem);
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $buybox-product-status-padding-top;
        }

        .msc-add-to-cart-extra-actions {
            @extend %secondary-button;
            border-color:$msv-secondary;
            padding-left: 0;
            padding-right: 0;
            position: relative;

            &:not(:disabled):hover,
            &:not(:disabled):focus {
                color: $msv-primary;
                background: #F0D09A;
                @include add-icon-icomoon($wishlist-full);
                &::before{
                    color:#d7191d;
                }
            }
        }

        .msc-add-to-wishlist {
            @extend %secondary-button;
            color:$msv-secondary;
            background: transparent;
            border-color:$msv-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            font-size: 1.4rem;
            min-width: 3rem;
            margin-left: $spacer*0.5;
            &:focus {
                border-color: $wafer-brown;
            }
            @media (hover: hover) {
                &:hover {
                    border-color: $wafer-brown;
                }
            }
        }
    }

    &__find-in-store {
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-light));
    }

    &__find-in-store-description {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
    }

    &__find-in-store-pickup-option {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
        @include add-icon($msv-Checkbox-Circle-Checked, before);

        &::before {
            margin-right: 10px;
            color: --msv-buybox-primary-btn-font-color;
        }
    }

    &__minmax_label_text {
        margin-top: 12px;
        font-weight: var(--msv-font-weight-bold);

        .msi-InfoCircle {
            @include add-icon($msv-InfoCircle);
            margin-right: $msv-icon-margin-right;
        }
    }

    &__find-in-store-button {
        @include button-default();
        @include secondary-button(var(--msv-buybox-secondary-btn-bg),
        var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__shopsimilarlooks {
        margin-top: $buybox-box-top-margin;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
                        $msv-primary);
        font-size: var(--msv-body-font-size-s);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }

    &__shop-similar-descriptions-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
                        $msv-primary);
        font-size: var(--msv-body-font-size-s);
        line-height: $msv-line-height-l;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            @include image($msv-dialog-msc-empty_image-size);

            .msc-empty_image {
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button(var(--msv-buybox-primary-btn-bg),
            var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        &__media-gallery {
            display: block;
            flex-basis: 100%;
            min-width: 100%;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: 0;
        }

        .ms-media-gallery {
            padding-bottom: 54px;

            .msc-carousel {
                &__indicators {
                    bottom: -40px;
                }

                &__control {
                    &__prev, &__next {
                        top: auto;
                        bottom: -50px;
                        cursor: pointer;
                        transform:none;
                    }

                    &__prev__icon {

                    }

                    &__next__icon {

                    }
                }

            }
        }
    }

    //mobile vp

    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;
    }

    .ms-buybox__media-gallery {
        position: relative;
    }

    .product-view-360 {
        &__image {
            @include media-breakpoint-down(md){
                display: none;
            }
            background: #fff;
            position: absolute;
            top: 0;
            right: 40px;
            height: calc(100% - 20px);
            width: calc(100% - 160px);
            > div {
                width:100% !important;
                height: 100% !important;
            }
            opacity: 1;
            visibility: visible;
            transition: opacity 0.25s;
            &.hidden {
                opacity: 0;
                visibility: hidden;
            }
        }
        &__ctn {
            margin-left:100px;
            text-align: center;
        }
        &__link {
            cursor: pointer;
            appearance: none;
            border: none;
            background: transparent;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            @include media-breakpoint-down(md){
                display: none;
            }
            span {
                display: none;
            }
            &:before {
                content:url(../../../images/360.svg);
            }
            &.active {
                border-color:#000;
            }
        }
    }
}
.product-view-360 {
    &__image {
        @include media-breakpoint-down(md){
            display: none;
        }
        background: #fff;
        position: absolute;
        top: 0;
        right: 40px;
        height: calc(100% - 20px);
        width: calc(100% - 160px);
        > div {
            width:100% !important;
            height: 100% !important;
        }
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s;
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
    }
    &__ctn {
        margin-left:100px;
        text-align: center;
    }
    &__link {
        cursor: pointer;
        appearance: none;
        border: none;
        background: transparent;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        @include media-breakpoint-down(md){
            display: none;
        }
        span {
            display: none;
        }
        &:before {
            content:url(../../../images/360.svg);
        }
        &.active {
            border-color:#000;
        }
    }
}
