$msv-alert-success-color: $msv-text-color;
$msv-alert-success-background-color: $msv-gray-300;

$msv-alert-close-button-color: var(--msv-font-primary-color);
$msv-alert-close-button-right: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-alert-line-margin-top: 8px;

.msc-alert {
    @extend %validation-error;
    display: block;
    margin-bottom: $msv-alert-margin-bottom;

    .msi-exclamation-triangle {
       display:none;
    }

    .msc-alert__line {
        margin-top: $msv-alert-line-margin-top;
    }

    .close {
        @include add-icon($msv-x-shape);
    }
}

.msc-alert-success {
    @extend %validation-success;
}

.msc-cart-line__product-quantity {
    .msc-alert__header {
        background-color: transparent;
        color: var(--msv-error-color);
        border-color: transparent;
        padding: 0;

        @include font-content-m(600);
        border: $msv-alert-border;
        border-radius: $msv-alert-border-radius;
        display: block;
        margin-bottom: $msv-alert-margin-bottom;
        position: relative;
        text-transform: none;

        .msi-exclamation-triangle {
            margin-right: $msv-alert-icon-margin;

            @include add-icon($msv-alert-icon, before);
        }
    }
}

.msc-alert-danger {
    color: var(--msv-error-color);
    background-color: $msv-pink;
    border-color: var(--msv-error-color);
}

.msc-alert-noborder {
    background-color: transparent;
    color: var(--msv-error-color);
    border-color: transparent;
    margin-bottom: 0;
    padding: 0;
}
