@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $padding in $paddings {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

@mixin basicTruncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin basic-outline-offset(
    $outline-width: 1px,
    $outline-style: dashed,
    $outline-color: $black,
    $outline-offset: -1px
) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin vfi {
   /* &:focus {
        border: 1px dashed $white;
        outline: 1px dashed $black;
    }*/
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
    position: $position;

    &:focus {
        outline: 1px dashed $white;

        &:#{$selector} {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            outline: 1px dashed $black;
            outline-offset: $offset;
        }
    }
}

@mixin validation($bg-color, $border, $color, $icon) {
    background-color: $bg-color;
    border: 1px solid $border;
    color: $color;
    font-size: 1.15rem;
    font-weight: bold;
    min-height: 4rem;
    padding:1.5rem 1.5rem 1.5rem 5.25rem;
    position: relative;
    border-radius: 4px;
    @include add-icon-icomoon($icon);
    &:before {
        position: absolute;
        top: 50%;
        left: 1.5rem;
        transform: translateY(-50%);
        border-radius: 50%;
        padding-bottom: 5px;
        color:#fff;
        background: $color;
        height: 2.5em;
        width: 2.5em;
        line-height: 2.5em;
        text-align: center;
        font-size: 1rem;
    }
    p {
        font-size: 1em;
    }
    > p {
        display: flex;
        min-height: 3rem;
        align-items: center;
    }
}

@mixin validation-success($bg-color: #DAFDD2, $border: #3FAC39, $color: #3FAC39, $icon:$information) {
    @include validation($bg-color, $border, $color, $icon);
}

@mixin validation-error($bg-color: #FCDFDF,
$border: #C93D40, $color: #C93D40, $icon: $error) {
    @include validation($bg-color, $border, $color, $icon);
}

@mixin divider($direction) {
    border-#{$direction}: 1.5px solid #{$msv-divider-color};
}

%validation-error {
    @include validation-error();
}
%validation-success {
    @include validation-success();
}