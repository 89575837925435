$ms-text-block-margin-top: 25px;

//style presets
:root {
    --msv-text-block-font-color: var(--msv-font-primary-color);
    --msv-text-block-font-size-s: var(--msv-body-font-size-s);
    --msv-text-block-font-size-m: var(--msv-body-font-size-m);
    --msv-text-block-font-size-l: var(--msv-body-font-size-l);
    --msv-text-block-font-size-xl: var(--msv-body-font-size-xl);
}

.ms-text-block {
    line-height: normal;
    margin-top: $ms-text-block-margin-top;
    margin-left: $spacer;
    margin-right: $spacer;

    .col-md-6 > & {
        @include media-breakpoint-up(lg){
            margin-left: 5rem;
            margin-right:5rem;
        }
    }
    .col-md-6:nth-child(odd) > & {
        @include media-breakpoint-up(md){
            margin-right:2.5rem;
        }
    }
    .col-md-6:nth-child(even) > & {
        @include media-breakpoint-up(md){
            margin-left:2.5rem;
        }
    }
    .col-md-12 > & {
        @include media-breakpoint-up(md){
            margin-left: 5rem;
            margin-right:5rem;
        }
    }
    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    &.fontsize__small {
        font-size: var(--msv-text-block-font-size-s);
    }

    &.fontsize__medium {
        font-size: var(--msv-text-block-font-size-m);
    }

    &.fontsize__large {
        font-size: var(--msv-text-block-font-size-l);
    }

    &.fontsize__extralarge {
        font-size: var(--msv-text-block-font-size-xl);
    }

    a {
        color: var(--msv-text-block-font-color);
        text-decoration: underline;
    }
}
