$black:#000000;
$stone-gray:#F5F2E9;
$mountain-gray:#B0B0B0;
$wafer-brown:#F0D09A;
$cream-brown:#A78053;

$msv-primary: #D7191D;
$msv-primary-hover: $wafer-brown;
$msv-secondary: #A78053;
$msv-secondary-hover: #b98f5e;



// bootstrap color variables
$msv-white: #FFFFFF;
$msv-black: #000000;
$msv-blue:    #0062AF;
$msv-green: #539A4F;
$msv-red: #D7191D;
$msv-pink: #FCDFDF;
$msv-cyan: #0776D1;
$msv-yellow:#FFFF00;
$msv-grey: #323130;

$msv-gray-100: $stone-gray;
$msv-gray-200: $stone-gray;
$msv-gray-300: $mountain-gray;
$msv-gray-400: $mountain-gray;
$msv-gray-500: #707070;
$msv-gray-800: #707070;
$msv-gray-900: #1D1D1D;
$msv-gray-1000: #2D2D2D;
$msv-gray-tint: #495057;



$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-selected-button-background: rgba(0, 0, 0, 0.02);
$msv-selected-hovered-button-background: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #F3F2F1;

// Social button color variables
$facebook-bg-color: #3C3C41;
$facebook-hover-color: #1D1D1D;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #1D1D1D;
$social-bg-color: #3C3C41;
$social-hover-color: #1D1D1D;

$msv-unfilled-rating-color: $msv-gray-800;

$msv-header__topbar: $msv-primary;

$bg--secondary:#F5F2E9;
// Style presets
:root {
    // Background
    --msv-bg-color: #fff;

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-gray-100};
    --msv-btn-input-border-color: #{$msv-gray-500};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$wafer-brown};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};

    // Buttons
    --msv-selected-button-background: #{$msv-selected-button-background};
    --msv-selected-hovered-button-background: #{$msv-selected-hovered-button-background};
}
