// style presets
:root {
    --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
    --msv-account-landing-tile-text-color: var(--msv-font-primary-color);

    // link
    --msv-account-landing-link-color: #{$msv-link-color};
}

[data-exp-event-id='MyAccount.PageView']{
    main {
        background: $bg--secondary;
        padding-top:$spacer-l;
        @include media-breakpoint-down(md){
            padding-top:$spacer-xl;
        }
        padding-bottom:$spacer-xl;
        > div:not(:first-child) > div {
            background: #fff;
            @include media-breakpoint-up(lg){
                > div:first-child {
                    padding-left: $spacer-l*0.5;
                }
                > div:last-child {
                    padding-right: $spacer-l*0.5;
                }
            }

        }
        > div:last-child > div {
            padding-bottom: $spacer-l;
        }
    }
}
.ms-account {
    &-welcome-tile {
        display: block;
        &__heading {
            @extend %h4;
            color:$msv-primary;
        }
    }

    &-generic-tile {

        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
        }

        &.order-tile {
            .ms-account-generic-tile__heading {
                @include add-icon-icomoon($cart);
            }
        }

        &.profile-tile {
            .ms-account-generic-tile__heading {
                @include add-icon-icomoon($user);
            }
        }
    }

    &-wishlist-tile__heading {
        @include add-icon-icomoon($wishlist);
    }

    &-order-templates-tile__heading {

    }

    &-address-tile__heading {
        @include add-icon-icomoon($marker);
    }

    &-loyalty-tile__heading {

    }

    &-organization-users-tile__heading {

    }

    &-customer-balance-tile__heading {

    }

    &-invoice-tile__heading {

    }

    &-generic-tile,
    &-wishlist-tile,
    &-order-templates-tile,
    &-address-tile,
   // &-loyalty-tile,
    &-organization-users-tile,
    &-customer-balance-tile,
    &-invoice-tile {
        background: $bg--secondary;
        margin: $spacer-l $spacer-l * 0.5 0 $spacer-l * 0.5;
        padding: $spacer-l;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__heading {
            font-size:2rem;
            font-weight: 400;
            border-bottom: 1px solid $msv-primary;
            padding-bottom: .5rem;
            flex-basis:100%;
            margin-bottom: 1.5rem;
            &:before {
                color:$msv-primary;
                margin-right: .75rem;
            }
        }

        &__links {

        }

        &__link {
            @extend %hyperlink;
        }

        &__link-button {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            cursor: pointer;
        }

        &__description {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-tile-text-size),
            $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);
        }

        &__value {
            @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-text-size),
            $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);

            &::before, &::after {
                content: " ";
            }
        }
    }
}
