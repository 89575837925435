// Form elements

@mixin error-message() {
    @include font-content-m($font-weight-bold);
    display: block;
    color: var(--msv-error-color);
}

@mixin form-control() {
    display: block;
    width: 100%;
    color: $msv-gray-tint;
    background-color: #FFFFFF;
    border: 1px solid #6B727A;
    border-radius: 0;

    font-family: Arial, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    height: 48px;
    padding: 4px 8px;
    background-clip: border-box;
    border-color: transparent;
    position: relative;
    outline-offset: 1px;
}

@mixin form-input-label() {
    font-size: .875rem;
    color: $msv-gray-500;
    display: block;
    margin-bottom: 4px;
}


@mixin form-input-checkbox($border-color:$msv-black,$color:$msv-primary,$border-color--hover:$msv-primary,$border-color--checked:$msv-black,$border-color--disabled:$msv-gray-300) {
    vertical-align: middle;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 10;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    ~ *:nth-child(2) {
        margin-left:0;
        display: flex;
        line-height: 1.3em;
        @include add-icon-icomoon($check);
        &:before {
            width: 1.2em;
            height: 1.2em;
            min-width: 1.2em;
            line-height: 1em;
            background: $white;
            color:transparent;
            border:2px solid $border-color;
            border-radius: 2px;
            display: inline-block;
            margin-right:$layout-gutter*0.75;
            text-align: center;
        }
    }
    &:hover ~ *:nth-child(2) {
        &:before {
            border-color: $border-color--hover;
        }
    }
    &:checked ~ *:nth-child(2) {
        &:before {
            color: $color;
            border-color: $border-color--checked;
        }
    }
    &:disabled ~ *:nth-child(2) ,
    &[disabled] ~ *:nth-child(2)  {
        color:$msv-gray-500;
        &:before {
            border-color: $border-color--disabled;
        }
    }
}
@mixin form-input-radio {
    @include vfi();
    vertical-align: middle;
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
}

@mixin form-input-radio-label {
    @include font-content-m(var(--msv-font-weight-light));
    vertical-align: middle;
    margin-left: 20px;
}

@mixin form-input-el($bg-color: var(--msv-bg-color), $color: var(--msv-font-primary-color), $border: transparent) {
    @include vfi();
    @include font-content-l();
    height: 48px;
    padding: 4px 8px;
    background-color: $bg-color;
    color: $color;
    border-color: $border;
    border-radius: 0;
}

@mixin form-input-el-error() {
    border: 1px dashed var(--msv-error-color);
    border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;

    &::before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-content-s-height-l();
    color: $msv-text-color;
    margin-bottom: 4px;
}

@mixin alert {
    background-color: $pink;
    border: 1px solid $red;
    max-width: 512px;
    padding: 8px;
}

@mixin alert-title {
    @include error-message();
    margin-bottom: 10px;
    margin-top: 2px;

    &::before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}

%input--text {
    border:1px solid $msv-gray-300;
    font-family: $msv-primary-font-family;
    font-size:.875rem;
    height: 2.6em;
    width:100%;
    box-sizing: border-box!important;
    padding:.4em .5em;
    transition: border .15s ease-out;
    border-radius: 0;
    &:focus {
        outline:none;
        border-color:#000;
    }
}
