$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-color: var(--msv-font-primary-color);
$flipper-disabled-opacity: 0.4;
$flipper-border-radius: 50%;
$flipper-border-color: transparent;

$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;

@mixin carousel-arrow {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    bottom:0.5rem;
    transition: none;
    transform: translate(-50%,-50%);
    z-index: 16;
    font-size: 1.15rem;
    width: 2.5em;
    height: 2.5em;
    left: 0;
    background: #fff;
    color:$msv-primary;
    border-radius: 50%;
    cursor: pointer;
    border:none;
    appearance: none;
    outline: none;
    @include media-breakpoint-down(xl){
        font-size: 1rem;
    }
    @include media-breakpoint-down(sm){
        top:auto;
    }

    i, .msc-carousel__control__prev__icon, .msc-carousel__control__next__icon {
        @include add-icon-icomoon($arrow-right);
        &:before {
            line-height: 1em;
            vertical-align: middle;
        }
    }


    @media (hover: hover) {
        &:hover {
            background:$wafer-brown;
        }
    }
}
.msc-ss-carousel {
    position: relative;
    touch-action: pan-y pinch-zoom;
    width: $carousel-width;
    height: auto;

    .msc-flipper {
        position: absolute;

        .msi-chevron-left {

        }

        .msi-chevron-right {

        }
    }

    &__flipper {
        @include carousel-arrow;
        z-index: 1;
        &:first-child {
           :before {
               transform: scaleX(-1);
           }
        }
    }

    &__flipper:disabled {
        opacity: 0;
    }

    &__flipper--next {
        right: 0;
        left: auto;
        transform: translate(50%,-50%);
    }

    &-strip {
        width: $carousel-strip-width;
        height: auto;
        overflow: hidden;
    }

    &-slide {
        display: flex;
        align-items: stretch;
        left: 0;
        padding-inline-start: 0;
        position: relative;
        transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
    }
}

.msc-ss-carousel-vert {
    position: relative;
    width: auto;
    height: $vert-carousel-height;
    display: inline-block;
    touch-action: pan-x pinch-zoom;

    &-slide {
        white-space: nowrap;
        height: auto;
        position: relative;
    }

    &-item {
        display: block;
        white-space: normal;
    }

    &-strip {
        height: $vert-carousel-strip-width;
        width: auto;
        overflow: hidden;

        img {
            max-width: initial;
        }
    }

    &__flipper {
        color: $flipper-color;
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 0);
        top: -($vert-flipper-height / 2);

        @include add-icon($msv-ChevronUp);

        &:disabled {
            opacity: $flipper-disabled-opacity;
        }
    }

    &__flipper--next {
        top: auto;
        bottom: -($vert-flipper-height / 2);

        @include add-icon($msv-ChevronDown);
    }
}
