$quickView-top: 240px;
$quickView-left: 5px;
$quickView-right: 24px;
$quickView-label-margin-bottom: 5px;
$quickView-box-top-margin: 20px;
$quickview-padding: 12px 15px;
$quickView-box-bottom-margin: 20px;
$quickView-box-top-margin: 20px;
$quickView-button-padding: 5px;
$quickView-description-margin-left: 30px;
$quickView-description-margin-top: 274px;
$quickview-product-title-bottom: 200px;
$quickview-product-title-height: 63px;
$quickView-add-to-cart-min-width: 290px;
$quickView-add-to-cart-min-height: 48px;
$quickView-add-to-cart-extra-actions-margin: 8px;
$quickView-add-to-cart-container-margin-right: 12px;
$quickView-add-to-cart-extra-actions-width: 48px;
$quickView-product-status-padding-top: 18px;
$quickView-quantity-input: 5px;
$quickView-quantity-input-margin-left: 8px;
$quickView-quantity-input-margin-right: 8px;
$quickView-incr-decr: 5px;
$media-gallery-width: 375px;
$media-gallery-height: 700px;
$msv-dialog-width: 778px;
$msv-dialog-height: 607px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-body-padding: 0 20px 0 0;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-button-width: 100%;
$msv-quickView-rating-star-bottom: 220px;
$msv-quickView-rating-star-left: 77px;
$msv-quickView-price-bottom: 210px;
$msv-quickView-price-left: 80px;
$msv-icon-margin-right: 8px;
$quickView-custom-amount-input-width: 80px;
$quickView-custom-amount-input-padding: 5px;
$quickview-placeholder-image-width: 310px;
$quickview-placeholder-image-height: 310px;
$quickView-quantity-alert-msg-width: 290px;

//style presets
:root {
    --msv-quickView-btn-bg: #{$msv-white};
    --msv-quickView-btn-border-color: #{$msv-gray-300};
    --msv-quickView-btn-font-size: var(--msv-body-font-size-l);
    --msv-quickView-incdec-btn-font-color: var(--msv-font-primary-color);

    //title
    --msv-quickView-title-font-size: var(--msv-body-font-size-xl);
    --msv-quickView-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-quickView-rating-star: var(--msv-font-primary-color);
    --msv-quickView-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-quickview-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-quickView-text-font-size: var(--msv-body-font-size-m);
    --msv-quickView-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-quickView-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-quickView-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-quickView-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-quickView-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-quickView-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-quickView-secondary-btn-border: var(--msv-accent-brand-color);

    // Indicators
    --msv-quickview-carousel-indicator-fill-color: #{$msv-gray-800};
    --msv-quickview-carousel-indicator-active-fill-color: #{$msv-gray-900};

    // Flippers
    --msv-quickview-carousel-flipper-color: #{$msv-gray-500};
    --msv-quickview-carousel-flipper-hover-color: #{$msv-gray-900};
}

 .ms-quickView {
    left: auto;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 10px;
    @include media-breakpoint-down(md) {
        top: 12px;
    }
     
    &__header .msc-modal__close-button {
        top: 20px;
         color:$msv-primary;
        z-index: 20;
     }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;

        .msc-rating {
            padding-top: 16px;
        }
    }

     &__dialog {
        .msc-modal__content {
            height: 100%;
            padding: 0;
            padding-top: 20px;
            background-color: #fff;
            border: none;
        }
    }

    &__loading {
        &__icon {
            border-radius: 55px;
            display: flex;
            font-size: 5rem;
            justify-content: center;
            margin: 1rem auto;
            padding: 15px;

            .ms-Spinner-circle {
                width: 28px;
                height: 28px;
            }

            .ms-Spinner-label {
                @include font-content(var(--msv-font-weight-light), $msv-font-size-l, $msv-line-height-s);
            }
        }

        &__msg {
            margin-bottom: 15rem;
            text-align: center;
        }
    }

    &__header {
        margin-bottom: 0;
    }

    &__button {
        @include button-default();
        @include primary-button();
        @include add-icon-icomoon($quickaddtocart);
        &:before {
            font-size: 2.25rem;
            display: block;

        }
        padding: 0;
        min-width: 0;
        cursor: pointer;
        display: none;
        .mobile & {
            display: block;
        }
        flex-grow: 1;
        overflow: hidden;
        text-align: center;
        width: 3rem;
        height: 3rem;
        line-height:3rem;
        @include media-breakpoint-down(md){
            display: block;
            &:before {
                font-size: 2rem;
            }
            width: 2.6rem;
            height: 2.6rem;
            line-height:2.6rem;
        }
        /*
        @include media-breakpoint-down(sm){
            &:before {
                font-size: 1.5rem;
            }
            width: 2rem;
            height: 2rem;
            line-height:2rem;
        }*/
    }

    &__seeDetailsbutton {
        cursor: pointer;
        font-weight: bold;
        line-height: 1.2em;
        margin: $gutter-y 0;
        padding: 0;
        border-bottom: 2px solid transparent;
        transition: color 0.15s linear;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            border-bottom-color: $msv-primary;
        }
    }

    &__product-add-to-cart {
        position: relative;
        margin-bottom: $gutter-y;
    }

    &__add-to-cart-container {
        flex-grow: 1;
        margin-right: 4px;
        .msc-alert-danger {
            width: $quickView-quantity-alert-msg-width;
        }

        .msc-add-to-cart {
            @extend %primary-button;
            @include add-icon-icomoon($cart, after, true, 1rem, true);
            width:100%;
        }
    }

    &__body {
        padding: $gutter-xl;
        @include media-breakpoint-down(sm){
            padding:$gutter-l 0;
        }
        margin: 0;
        max-height: 100%;
        height: 100%;
        input:focus, textarea:focus, select:focus {
            /* stylelint-disable-next-line declaration-no-important -- Overriding the changes done in the modal scss to show the outline on focus. */
            outline: -webkit-focus-ring-color auto 1px !important;
            /* stylelint-disable-next-line declaration-no-important -- Overriding the changes done in the modal scss to show the outline on focus. */
            outline-color: -webkit-focus-ring-color !important;
            /* stylelint-disable-next-line declaration-no-important -- Overriding the changes done in the modal scss to show the outline on focus. */
            outline-style: auto !important;
            /* stylelint-disable-next-line declaration-no-important -- Overriding the changes done in the modal scss to show the outline on focus. */
            outline-width: 1px !important;
        }

        .msc-rating {
            &__count,
            &__star {
                color: var(--msv-quickView-rating-star);
                margin-top: 5px;
            }

            &__half-star {
                &::after {
                    color: var(--msv-quickView-rating-star);
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            .msc-rating {
                &__count, &__group {
                    bottom: $msv-quickView-rating-star-bottom;
                    left: $msv-quickView-rating-star-left;
                    position: relative;
                }
            }
        }

        .msc-price__actual {
            @include font-content-xl();
            color: var(--msv-quickView-text-font-color);
        }

        .msc-price__strikethrough {
            @include font-content-xl(var(--msv-font-weight-light));
        }

        .ms-quickView__key_in_price {
            margin-top: 20px;

            .ms-quickView__key_in_price-label {
                @include font-content-l(var(--msv-font-weight-light));
                color: var(--msv-quickView-title-font-color);
                display: block;
                margin-bottom: $quickView-label-margin-bottom;
            }

            .ms-quickView__key_in_price_custom-amount__input {
                @include form-control();
                -moz-appearance: textfield;
                padding: $quickView-custom-amount-input-padding;
                text-align: left;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }
            }
        }

        .ms-text-block {
            margin-top: 0;
            margin-bottom: 1rem;

            @include font-content(var(--msv-font-weight-normal),
            var(--msv-quickView-text-font-size), $msv-line-height-m);
        }

        .product-add-to-cart {
            margin-top: 20px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }

            .msc-add-to-cart {
                @include primary-button(var(--msv-quickView-primary-btn-bg),
                var(--msv-quickView-primary-btn-font-color), var(--msv-quickView-primary-btn-border));
                font-size: var(--msv-quickView-btn-font-size);
                flex-grow: 1;
                line-height: $msv-line-height-l;
            }

            .msc-add-to-cart-extra-actions {
                @include font-glyph-standard();
                @include secondary-button(var(--msv-quickView-secondary-btn-bg),
                var(--msv-quickView-secondary-btn-font-color), var(--msv-quickView-secondary-btn-border));
                color: var(--msv-quickView-btn-extra-actions-color);
                flex: 0 0 $quickView-add-to-cart-extra-actions-width;
                min-width: $quickView-add-to-cart-extra-actions-width;
                margin-left: $quickView-add-to-cart-extra-actions-margin;
                padding-left: 0;
                padding-right: 0;
                position: relative;
                width: $quickView-add-to-cart-extra-actions-width;

                &:not(:disabled):hover,
                &:not(:disabled):not(.disabled):active:focus {
                    border-color: transparent;
                    color: var(--msv-quickView-btn-extra-actions-color);
                }
            }
        }

        .msc-dropdown__select {
            @include form-control();
            height: 50px;
            width: 100%;

            @media (max-width: $msv-breakpoint-m) {
                width: 95%;
            }
        }

        .quantity {
            margin-top: 8px;
            font-size: 1.25rem;
        }
    }
    &__footer {
        width: 100%;
        display: block;
        text-align: center;
        border-top: 1px solid #b0b0b0;
        padding-left: $gutter-xl;
        padding-right: $gutter-xl;
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__inventory-info {
        margin-top: $quickView-box-top-margin;
        display: none;
    }

    &__product-title {
        @extend %h3;
        font-size:1.5rem;
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-quickView-text-font-size), $msv-line-height-m);
        color: var(--msv-quickView-text-font-color);
        margin-bottom: $quickView-box-bottom-margin;
        margin-top: $quickView-box-top-margin;

        @media (max-width: $msv-breakpoint-m) {
            margin-left: $quickView-description-margin-left;
            position: relative;
            top: $quickView-description-margin-top;
        }
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));

        @media (max-width: $msv-breakpoint-m) {
            bottom: 50px;
        }
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-quickView-title-font-color);
        }
    }

    &__media-gallery {
        display: block;
        flex-wrap: nowrap;
        min-height: 400px;
        position: relative;
        .msc-carousel {
            position: relative;
            align-items: center;
            display: flex;
            .msc_image {
                margin: auto;
            }
        }
        .product-view-360__image {
            width: calc(100% - 2rem);
            height: calc(100% - 3rem);
            left: 1rem;
        }
        .product-view-360__link {
            position: absolute;
            bottom: -0.25rem;
            right: 0rem;
            width: 72px;
            height: 40px;
            padding: 0;
            z-index: 100;
            &:before {
                content: '';
                background: transparent url(../../../images/360.svg) no-repeat;
                background-size: 100% auto;
                width: 100%;
                height: 100%;
                display: block;
            }
            &.active {
                border:transparent;
            }
        }
        &__carousel,.msc-carousel {
            @include image($quickview-placeholder-image-width, $quickview-placeholder-image-height);


            height: 400px;

            &__indicators {
                bottom: 0.75rem;
                display: flex;
                div {
                    display: none;
                }

                li {

                }

                .active {

                }
            }

            &__control {
                &__prev, &__next {
                    bottom: 0rem;
                }

                &__prev__icon {

                }

                &__next__icon {

                }
            }

            &__control__prev {
                left: 0;
                margin-left: 0;
            }
            &__control__next {
                margin-right: 0;
            }
        }

        .ms-media-gallery__thumbnails-container {
            display: none;
            height: 100px;
            width: fit-content;

            .msc-ss-carousel {
                bottom: 426px;
                height: fit-content;
                width: fit-content;
            }

            .msc-ss-carousel-slide {
                display: block;
            }

            .ms-media-gallery__thumbnail-item:not(:last-child) {
                margin-bottom: 10px;
                margin-right: 20px;
            }

            .ms-media-gallery__carousel {
                margin-bottom: 0;
            }
        }
    }

    &__add-to-wishlist-container {
        align-self: flex-end;
        margin-top: 30px;
        .msc-add-to-wishlist {
            @extend %secondary-button;
            color:$msv-secondary;
            border-color:$msv-secondary;
            font-size: 1.4rem;
            position: relative;
            min-width: 3rem;
            padding: 0.5rem;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: $msv-primary;
                background: $wafer-brown;
                border-color: $wafer-brown;
                @include add-icon-icomoon($wishlist-full);
            }
        }

        .ms-wishlist-items__product-status {
            //display: inline-block;
            position: relative;
            right: 350px;
            top: 44px;
            width: 254px;
            display: none;
            @include add-icon($msv-Checkbox-Checked, before);
            cursor: pointer;
            outline: none;

            &::before {
                bottom: 12px;
                position: relative;
            }

            .msc-alert {
                background-color: transparent;
                border: none;
                bottom: 45px;
            }

            .msc-alert button.close {
                display: none;
            }

            @media (max-width: $msv-breakpoint-m) {
                left: -194px;
                top: 40px;
                width: 220px;
            }
        }
    }

    &__quantity {
        margin-top: 13px;
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-quickView-title-font-color);
        display: block;
        margin-bottom: $quickView-label-margin-bottom;
    }

}

.msc-modal__dialog {
    &.ms-quickView__dialog {
        width: 100%;
        max-width: 488px;
        @include media-breakpoint-down(sm){
            max-width: none;
        }
        height: 100%;
        float: right;
        margin: 0;

        right: -33.332%;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }
}

.msc-modal.fade.show .ms-quickView__dialog {
    right: 0;
    overflow-y: hidden;
}