.ms-product-specification__nutritional-values {
    display: flex;
    flex-wrap: wrap;
    .ms-product-specification__facts-ingredients.layout_table & {
        background: #fff;
        border: 1px solid #000;
        box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
        width: 374px;
        min-width: 374px;
        @include media-breakpoint-down(sm){
            width: 304px;
            min-width: 304px;
        }
        text-align: left;
        color: #000000;
        padding: 15px 10px;
    }
    .label {
        display: inline-block;
        margin-right: 0.3em;
        line-height: 1.2em;
    }
    .table_row {
        border-bottom: 1px solid #838181;
        display: flex;
        padding-top: 4px;
        align-items: end;
        flex-basis: 100%;
        span {
            line-height: 1.25em;
            display: inline-block;
        }
        .font-bold {
            font-weight: 900;
        }
        &:last-child,
        &.amountperserving,
        &.servingspercontainer {
            border: 0;
        }
        &.indentation {
            &__1 {
                padding-left: 18px;
            }
            &__2 {
                padding-left: 34px;
            }
            &__3 {
                padding-left: 50px;
            }

        }
        &.indentation__10 {
            justify-content: flex-end;
            margin-left: auto;
            text-align: right;
        }
        &.nextIsPercentageField {
            flex-basis: 75%;
        }
        &.percentage {
            flex-basis: 25%;
            .label {
                display: none;
            }
        }
        &.servingspercontainer {
            .label {display:none;}
        }
        &.servingsize {
            span.value {
                .ms-product-specification__facts-ingredients.layout_table & {
                    justify-content: flex-end;
                    margin-left: auto;
                    text-align: right;
                    font-weight: bold!important;
                }
            }
        }
        &.calories {
            padding-top: 0;
            .label {
                line-height: 1em;
            }
            .value {
                .ms-product-specification__facts-ingredients.layout_table & {
                    line-height: 1em;
                    font-weight: bold!important;
                    font-size: 36px!important;
                    justify-content: flex-end;
                    margin-left: auto;
                    text-align: right;
                }

            }
        }
        &.totalsugars {
            position: relative;
            &:after {
                position: absolute;
                content: "";
                bottom: -2px;
                left: 0;
                width: 34px;
                background: #fff;
                height: 3px;
            }
        }
        &.separator {
            padding-top: 0;
            border: 0;
            + div {
                padding-top:2px;
            }
        }
        hr.separator {
            padding: 0;
            margin: 0;
            background: #000;
            border: 0;
            width:100%;
            &.h_5 {
                height:5px;
            }
        }

    }
}