//spacing
$header-container-spacing-desktop: 0;
$header-container-spacing-mobile-left:0;
$header-container-spacing-mobile-right: 0;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 0;
$signin-info-padding: 12px 14px;
$header-height: 3rem;
$header-mobile-height: 3.5rem;
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: $spacer/2;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 8px;
$header-list-item-link-padding: 0.5rem $spacer-m;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 0;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-store-selector-btn-padding: 12px 10px;
$header-icon-font-size:1.4rem;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-border: var(--msv-border-color);
    --msv-header-font-color: #fff;
    --msv-header-font-size: var(--msv-body-font-size-m);
}

header {
    background-color: var(--msv-header-bg);
    z-index: 100;
    position: relative;
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    border: 0;

    margin-left: auto;
    margin-right: auto;
    padding-left: $header-container-spacing-mobile;
    padding-right: $header-container-spacing-mobile;
    padding-top: $heading-padding-top;
    z-index: 1;
    position: relative;
    .msc-btn {
        padding:$spacer/2;
    }
    &__container {
        padding-bottom: $msv-default-container-padding-bottom;

        .ms-header__logo {
            align-self: flex-start;
            z-index:100000;
            img {
                max-height: 93px;
            }
            a {
                @include vfi();
                display: block;

                &:focus {
                    outline-offset: -2px;
                }
            }
        }

        .ms-header__collapsible-hamburger {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            .ms-header__divider {
                background: var(--msv-header-bg);
                border-bottom:1px solid #000;
                padding: $divider-padding-m;
            }

            .ms-nav.mobile-vp {
                padding-top: $spacer-m;
                padding-bottom: $spacer-m;
                display: flex;
                flex-direction: column;
            }
            nav {
                font-size: 1rem;
                button, a {
                    font-size: 1rem;
                    text-decoration:none;
                    > span {
                        position: relative;
                        &:before {
                            content: "";
                            background: $msv-primary;
                            height: 2px;
                            width: 0;
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                            transition: all 0.25s;
                        }
                    }
                    &:focus {
                        > span:first-child:before {
                            width: 100%;
                        }
                    }
                    @media (hover: hover) {
                        &:hover > span:first-child:before {
                            width: 100%;
                        }
                    }
                }
                > a {
                    font-weight: bold;
                }
                > div {
                    > button {
                        font-weight: bold;
                        color:#000;
                    }
                    > div > div {
                        padding-left:$spacer;
                        > a, > div > button {
                            color:$msv-primary;
                            font-weight: bold;
                        }
                        > div > div {
                            padding-left:$spacer;
                            font-weight: normal;
                            a {
                                position: relative;
                                padding-left: 0!important;
                                padding-right: 0!important;
                                margin-left: 1.5rem;
                                display: block!important;
                                max-width: max-content;
                                &:before {
                                    content: "";
                                    background: $msv-secondary;
                                    height: 2px;
                                    width: 0;
                                    position: absolute;
                                    bottom: 2px;
                                    left: 0;
                                    transition: all 0.25s;
                                }
                                &:focus {
                                    &:before {
                                        width: 100%;
                                    }
                                }
                                @media (hover: hover) {
                                    &:hover:before {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin: 0 8px;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        background-color: transparent;
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
        @include media-breakpoint-up(lg){
            &:focus {
                color:$wafer-brown;
            }
            @media (hover: hover) {
                &:hover {
                    color:$wafer-brown;
                }
            }
        }
    }
    .ms-search__icon,
    .ms-header__preferred-store-btn,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .ms-header__profile-button {
        @include media-breakpoint-down(md){
            &:focus {
                color:$msv-primary;
                text-decoration: none;
            }
            @media (hover: hover) {
                &:hover {
                    color:$msv-primary;
                    text-decoration: none;
                }
            }
        }
    }
    .ms-site-picker__drawer button {
        @include media-breakpoint-down(md){
            @media (hover: hover) {
                &:hover {
                    color:$msv-primary;
                    text-decoration: none;
                }
            }
        }
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .ms-header__profile-button {
        @include media-breakpoint-down(md) {
            color:#000;
        }
    }
    .ms-search__form-cancelSearch {
        color:$msv-primary;
        background: $wafer-brown;

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
    }


    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .msc-btn {
       // height: $header-height;
    }

    .ms-header__topbar,
    .ms-header__topbar--aad {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        height: $header-mobile-height;
        background: $msv-header__topbar;
        padding:0 $spacer-m;
        @include media-breakpoint-down(md){
            .ms-site-picker {
                display: none;
            }
        }
    }
    .ms-header__topbar--aad {
        align-items: flex-start;
        .ms-header__logo {
            min-height: 0;
            img {
                max-height: 93px;
                width: auto;
                border-radius: 0;
            }
        }
    }
    
    .ms-search {
        display: none;
    }

    .ms-header__desktop-view {
        display: none;
    }

    .ms-header__account-info {
        display: none;
        height: $header-height;
        justify-content: flex-end;
        margin-left: $signin-info-spacing-left;

        &__account-link-container {
            height: $account-link-height;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            align-items: center;
            display: flex;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            padding: $signin-link-padding;

            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            color: var(--msv-header-font-color);
            @include media-breakpoint-down(md) {
                color:#000;
            }
            font-weight: var(--msv-font-weight-normal);
            &-text {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
            @include add-icon-icomoon($user);
            @include media-breakpoint-up(lg) {
                @media (hover: hover) {
                    &:hover {
                        color: $wafer-brown;
                        text-decoration: none;
                    }
                }

                &:focus {
                    color: #F0D09A;
                    text-decoration: none;
                }
            }
            @include media-breakpoint-down(md) {
                @media (hover: hover) {
                    &:hover {
                        color: $msv-primary;
                        text-decoration: none;
                    }
                }
                &:focus {
                    color: $msv-primary;
                    text-decoration: none;
                }
            }
            &:before {
                font-size:$header-icon-font-size;
            }

        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            .msc-btn {

            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        display: none;
        height: $header-height;
        width: $header-icon-width;

        @include add-icon-icomoon($wishlist);
        &:before {
            font-size:$header-icon-font-size;
        }
        &::before {
            text-align: center;
            vertical-align: text-bottom;
        }
        &:hover {
            color:$wafer-brown;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        align-items: center;
        background: transparent;
        display: flex;
        height: $header-height;
        justify-content: center;
        width: $header-icon-width;
        color:#fff;
        &:focus {
            outline-offset: -1px;
        }
        @include add-icon-icomoon($hamburgher);
        &:before {
            font-size: 1.6rem;
        }
        &:hover {
            text-decoration: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        display: flex;
        align-items: center;

        @include add-icon-icomoon($cart);
        &:before {
            font-size:$header-icon-font-size;
        }

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media (max-width: $msv-breakpoint-xl) {
        padding-left: $header-container-spacing-mobile-left;
        padding-right: $header-container-spacing-mobile-right;

        .msc-wishlist-icon {
            @include add-icon-icomoon($wishlist);
            &::before {
                padding-right: 0.5rem;
                font-size:1.4rem;
            }
        }

        &__container {
            .ms-header__topbar {
                justify-content: space-between;
                padding-left:8px;
                .ms-header__logo {
                    margin: $header-logo-margin;
                }
            }

            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            background: transparent;
                            text-align: left;
                            width: 100%;
                            padding:0.5rem $spacer-m;
                            .ms-nav__drawer-open {
                                @include add-icon($msv-ChevronUp, after);
                                &:after {
                                    color:$msv-primary;
                                }
                            }

                            .ms-nav__drawer-close {
                                @include add-icon($msv-ChevronDown, after);
                                &:after {
                                    color:$msv-primary;
                                }
                            }

                            .drawer__glyph__end {
                                position: absolute;
                                right: $spacer-m;
                            }
                        }

                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }

                    .ms-nav__list__item__link {
                        align-items: center;
                        display: flex;
                        padding: $header-list-item-link-padding;
                        &.mobile-only {
                            font-weight: bold;
                            @include media-breakpoint-up(lg){
                              display: none!important;
                            }
                          }
                    }

                }
            }
        }

        .ms-header__account-info.account-mobile {
            display: unset;
            margin-left: 0;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .drawer__button {
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                @include add-icon-icomoon($user);

                &::before {
                    padding-right: $header-icon-padding-right;
                    font-size: 1.4rem;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-ChevronUp, after);
                }

                .ms-header__drawer-close {
                    @include add-icon($msv-ChevronDown, after);
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .drawer__glyph__end {
                    position: absolute;
                    right: $header-icon-padding-right;
                }
            }

            .ms-header__signin-button {
                display: flex;
                justify-content: flex-start;

               // @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-btn {
                outline-offset: -1px;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0;
            margin-top: 0;
            position: absolute;
            width: 100%;
            z-index: 1012;
            padding-top:1.5rem;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }

            .ms-search {
                justify-content: flex-start;
                display: flex;

                .ms-search__label {
                    display: flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left;
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-desktop;
        padding-right: $header-container-spacing-desktop;

        .ms-header__desktop-view {
            display: block;
        }

        .ms-nav.desktop-vp {
            display: flex;
        }

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            background: var(--msv-header-bg);

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: text-bottom;
            }
        }

        &__profile-button[aria-expanded=true] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded=false] {
            &::after {
                content: $msv-ChevronDown;
            }
        }

        &__divider {
           display: none;
        }

        .ms-header__account-info.account-desktop {
            display: flex;
        }

        .ms-header__collapsible-hamburger {
            display: none;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search {
            display: flex;

            &__icon {
                align-items: center;
                color: var(--msv-header-font-color);
                font-weight: normal;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-cart-icon {
            // padding-left: $cart-icon-spacing-left;
            width: unset;

            &::before {
                width: unset;
                margin-right: 8px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-header {
            &__preferred-store-btn {
                padding: $header-store-selector-btn-padding;
            }

            &__preferred-store-text {
                display: none;
            }
        }
    }

    @supports (-webkit-touch-callout: none) {
        .ms-header__preferred-store-btn {
            &:focus {
                border: none;
                outline: 0;
            }
        }
    }

    &__mobile-hamburger-menu-links {
        padding: $spacer $spacer 0 $spacer;
    }
}
