@import "../00-settings/colors";
@import "../00-settings/font";
@import "../00-settings/responsive-breakpoints";

$msv-btn-min-width: 8.75rem;
$msv-btn-side-padding: 1rem;
$msv-btn-vertical-padding: 12px;

:root {
    // Background
    --msv-secondary-button-background-color: #{$msv-white};
}

@mixin button-link($color: $msv-link-color) {
    border-color: transparent;
    background-color: transparent;
    color: $color;

    &:hover,
    &:focus {
        text-decoration: underline;
        cursor: pointer;
    }
}

@mixin primary-button($bg-color: var(--msv-accent-brand-color),
$color: var(--msv-font-secondary-color), $border-color: var(--msv-accent-brand-color)) {
    background-color: $bg-color;
    border: 1px solid $border-color;
    color: $color;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    border-radius:4px;
    font-weight: bold;
    font-size:1rem;
    min-width: $msv-btn-min-width;
    text-align: center;
    transition: all .2s;
    justify-content: center;
    &:focus {
        color:$bg-color;
        background-color: var(--msv-accent-brand-alt-color);
        border-color: var(--msv-accent-brand-alt-color);
        text-decoration: none;
    }
    @media (hover: hover) {
        &:hover {
            color:$bg-color;
            background-color: var(--msv-accent-brand-alt-color);
            border-color: var(--msv-accent-brand-alt-color);
            text-decoration: none;
        }
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        border-color: $mountain-gray;
        background-color: $mountain-gray;
        color: var(--msv-font-secondary-color);
    }
}

@mixin secondary-button($bg-color: var(--msv-secondary-button-background-color),
$color: var(--msv-font-primary-color), $border-color: var(--msv-accent-brand-color), $disableBtn: true) {
    background-color: $bg-color;
    border: 1px solid $border-color;
    color: $color;
    font-size:1rem;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    min-width: $msv-btn-min-width;
    border-radius:4px;
    transition: all .2s;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    &:focus {
        background-color: var(--msv-accent-secondary-btn);
        border-color:$msv-primary;
        text-decoration: none;
    }
    @media (hover: hover) {
        &:hover {
            background-color: var(--msv-accent-secondary-btn);
            border-color:$msv-primary;
            text-decoration: none;
        }
    }

    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            border-color: $msv-gray-300;
            background-color: $msv-gray-300;
            color: var(--msv-font-secondary-color);
        }
    }
}

@mixin button-default() {
    @include vfi();
    font-size:1rem;
    cursor: pointer;
    padding: 12px 20px;
   // height: 48px;
    min-width: 160px;
    text-align: center;
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}
